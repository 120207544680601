
<template>
  <div class="p-card widgets-marketplace-toolbar p-0 m-0 align-items-center">

    <div class="flex align-items-center">
      <Toolbar class="flex-1 p-0 m-0">
        <template #start>
          <Button class="p-button-text" label="Create Widget" icon="pi pi-plus" @click="showWidgetCreationDialog=true"></Button>
          <Menu ref="menu" id="overlay_menu" :model="menuItems" :popup="true" />
          <Button icon="pi pi-ellipsis-v" label="Category" class="p-button-text" @click="(e) => { toggleMenu(e); }"
            aria-haspopup="true" aria-controls="overlay_menu" />

          <Divider layout="vertical" />
          <div class="flex flex-row gap-3">
            <Dropdown v-model="selectedTemplate" @change="onChange($event)" option-label="name"
              option-group-children="name" :options="templates ?? []" placeholder="Select a template">

            </Dropdown>
            <Button :disabled="selectedTemplate.profileId == null" label="Import Template" @click="confirmImport()">
            </Button>
          </div>

        </template>
      </Toolbar>
    </div>

  </div>
  <EditCategoryView :visible="showEditDialog" :on-hide="(edited: any) => {
    if (edited) {
      onCategoryEdited();
      showEditDialog = false;
    }
  }" />
  <CategoryCreateDialog :visible="showCategoryCreationDialog" :on-hide="(created: any) => {
    showCategoryCreationDialog = false;
    if (created) {
      onCategoryCreated();
      showCategoryCreationDialog = false;
    }
  }"/>

  <ConPopupDialog title="Create Widget"
    :visible="showWidgetCreationDialog" :draggable="false" style="max-width: 90%; min-width: 60%;max-height: 90%;" @onHide="showWidgetCreationDialog=false">
    <WidgetsCreateForm @onCancel="showWidgetCreationDialog=false"  @widgetCreated="showWidgetCreationDialog=false; emits('widgetCreated')">
    </WidgetsCreateForm>
  </ConPopupDialog>
</template>
<script setup lang="ts">
import Toolbar from 'primevue/toolbar';
import Button from "primevue/button"
import Dropdown from 'primevue/dropdown';
import { type TemplateListResponse_Template, UsersService, type CloneTemplateRequest } from '@/apis/earth-api';
import { onMounted, ref, type Ref } from "vue";
import { CategoryCreateDialog, EditCategoryView, WidgetsCreateForm } from '@/earth/components';
import { useToast } from 'primevue/usetoast';
import { useConfirm } from 'primevue/useconfirm';
import Menu from 'primevue/menu';
import Divider from 'primevue/divider';
import { ConPopupDialog } from '@congatec/primevue-components-lib';

const props = defineProps<{
  onSearchTermChanged: (searchTerm?: string) => any,
  categoryCreated: () => any,
  categoryEdited: () => any,
}>();

const emits = defineEmits(['widgetCreated','categoryCreated','categoryEdited'])

const toast = useToast();
const confirm = useConfirm();
const menu = ref();
const showEditDialog = ref<boolean>(false);
const showCategoryCreationDialog = ref<boolean>(false);
const showWidgetCreationDialog = ref<boolean>(false)
const selectedTemplate: Ref<TemplateListResponse_Template> = ref({});
var templates = ref<TemplateListResponse_Template[]>([]);
const menuItems = ref([
  {
    label: 'Create',
    icon: 'pi pi-plus',
    command: () => {
      showCategoryCreationDialog.value = true;
    }
  },
  {
    label: 'Edit',
    icon: 'pi pi-pencil',
    command: () => {
      showEditDialog.value = true;
    }

  }
]);


function onCategoryCreated() {
  props.categoryCreated()

}
function onCategoryEdited() {
  console.log("onCategoryEdited")
  props.categoryEdited()
}
onMounted(async () => {
  await getTemplates();
})

async function getTemplates() {
  var templateResponse = await UsersService.getApiV1UsersTemplateList();
  if (templateResponse.list) {
    templates.value = templateResponse.list;
  }
}

const onChange = (event: any) => {
  console.log("onChange: ", event);
  console.log("selectedTemplate: ", selectedTemplate);

}

const onImport = async () => {
  var request: CloneTemplateRequest = { templateProfileId: selectedTemplate.value.profileId, targetTabId: null }
  try {
    await UsersService.putApiV1UsersCloneTemplate(request);
    toast.add({ severity: 'success', summary: 'Success', detail: 'Entered Impersonate-Mode', life: 3000 });
    // TODO reload page with confirm dialog?
    window.location.reload();
  }
  catch (ex: any) {
    toast.add({ severity: 'error', summary: 'Error', detail: 'An error occured: ' + ex.message, life: 3000 });
  }

}

const toggleMenu = (event: any) => {
  menu.value.toggle(event);
};

const confirmImport = () => {
  console.log("confirmImport");
  confirm.require({
    message: 'Do you really want to import the template "' + selectedTemplate.value.name + '"? Your "Home" Dashboard will be overwritten!',
    header: 'Import Confirmation',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-info',
    accept: async () => {
      var request: CloneTemplateRequest = { templateProfileId: selectedTemplate.value.profileId, targetTabId: null }
      try {
        await UsersService.putApiV1UsersCloneTemplate(request);
        toast.add({ severity: 'success', summary: 'Success', detail: 'Entered Impersonate-Mode', life: 3000 });
        // TODO reload page with confirm dialog?
        window.location.reload();
      }
      catch (ex: any) {
        toast.add({ severity: 'error', summary: 'Error', detail: 'An error occured: ' + ex.message, life: 3000 });
      }
    },
  });
}


</script>
<style  lang="scss" >
//Hier könnte ihr style stehen
</style>