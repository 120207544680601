<script setup lang="ts">
import { useSlots, ref, type Ref } from "vue";
import type { ObjectListWidgetsResponse_Widget } from "@/apis/earth-api";
//import { ConTldr } from "@congatec/primevue-components-lib";
import Textarea from 'primevue/textarea';
import Image from 'primevue/image';

const props = defineProps<{
    widget?: ObjectListWidgetsResponse_Widget,
}>();
console.log(props.widget);
const slots = useSlots();
const details: Ref<string> = ref(props.widget?.model.details);
console.log("Details: ", details.value);
</script>
<template>
    <div class="felx flex-column">
        <div class="flex">
            <h3 v-if="props.widget"> 
                {{ props.widget.model.title }}
            </h3>
        </div>
        <div class="flex">
            <h4 v-if="props.widget?.model.subtitle"> 
                {{ props.widget.model.subtitle }}
            </h4>
        </div>
        <div v-if="widget?.model.url" style="height:82vh; width: 100%">
            <iframe  :title="widget?.model.title" :src="widget?.model.url" style="width: 100%;height: 100%;"></iframe>
        </div>
        <div class="flex justify-content-center">
            <Image v-if="widget?.base64PreviewImage" :src="'data:image/*;base64,' + widget?.base64PreviewImage" alt="Image"  />
        </div>
        <div class="flex align-content-between flex-column" v-if="props.widget?.model.details">
            <!-- <span class="font-bold flex-1 text-md">Details</span> -->
            <Textarea class="w-auto" disabled v-model="details" rows="5" cols="30" />
        </div>
    </div>
    <!-- <BaseWidget v-bind="props" :type="WidgetType.EMBEDDED" class="con-embedded-widget">
        <template #default>
            <div class="flex-1 h-full">
                <div class="flex flex-1 justify-content-center mt-2 h-full"
              
                >
                    <iframe
                    class="con-embedded-widget-iframe flex flex-1 mb-5 w-full" 
                    :title="model.title" 
                    :src="model.url"
                        frameborder="0"
                        height=""
                        width=""
                        
                        ></iframe>
                </div>

            </div>
        </template>

        <template v-for="(_, name) in slots" v-slot:[name]="scopedSlotData">
            <slot :name="name" v-bind="scopedSlotData"></slot>
        </template> 
    </BaseWidget> -->
    
    
</template>