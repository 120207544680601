/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DirectoryListResponse } from '../models/DirectoryListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StorageService {

    /**
     * @param path 
     * @returns DirectoryListResponse OK
     * @throws ApiError
     */
    public static getApiV1StoragesDirectories(
path?: string,
): CancelablePromise<DirectoryListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/storages/directories',
            query: {
                'Path': path,
            },
        });
    }

}
