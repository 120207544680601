/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateCategoriesRequest } from '../models/CreateCategoriesRequest';
import type { CreateCategoriesResponse } from '../models/CreateCategoriesResponse';
import type { CreateWidgetCategoriesRequest } from '../models/CreateWidgetCategoriesRequest';
import type { CreateWidgetCategoriesResponse } from '../models/CreateWidgetCategoriesResponse';
import type { DeleteCategoriesRequest } from '../models/DeleteCategoriesRequest';
import type { DeleteCategoriesResponse } from '../models/DeleteCategoriesResponse';
import type { EditCategoriesRequest } from '../models/EditCategoriesRequest';
import type { EditCategoriesResponse } from '../models/EditCategoriesResponse';
import type { EditWidgetCategoriesRequest } from '../models/EditWidgetCategoriesRequest';
import type { EditWIdgetCategoriesResponse } from '../models/EditWIdgetCategoriesResponse';
import type { ListCategoriesOrderFields } from '../models/ListCategoriesOrderFields';
import type { ListCategoriesOrderFieldsOrderingFieldsBase } from '../models/ListCategoriesOrderFieldsOrderingFieldsBase';
import type { ListCategoriesResponse } from '../models/ListCategoriesResponse';
import type { ListWidgetCategoriesRequest } from '../models/ListWidgetCategoriesRequest';
import type { ListWidgetCategoriesResponse } from '../models/ListWidgetCategoriesResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CategoriesService {

    /**
     * @param requestBody 
     * @returns CreateCategoriesResponse Success
     * @throws ApiError
     */
    public static postApiV1CategoriesCreateCategory(
requestBody?: CreateCategoriesRequest,
): CancelablePromise<CreateCategoriesResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Categories/CreateCategory',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param pageNumber 
     * @param pageSize 
     * @param skip 
     * @param orderingFields 
     * @param availableOrderingFields 
     * @returns ListCategoriesResponse Success
     * @throws ApiError
     */
    public static getApiV1CategoriesListCategories(
pageNumber?: number,
pageSize?: number,
skip?: number,
orderingFields?: Array<ListCategoriesOrderFieldsOrderingFieldsBase>,
availableOrderingFields?: ListCategoriesOrderFields,
): CancelablePromise<ListCategoriesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Categories/ListCategories',
            query: {
                'PageNumber': pageNumber,
                'PageSize': pageSize,
                'Skip': skip,
                'OrderingFields': orderingFields,
                'AvailableOrderingFields': availableOrderingFields,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns CreateWidgetCategoriesResponse Success
     * @throws ApiError
     */
    public static postApiV1CategoriesCreateWidgetCategory(
requestBody?: CreateWidgetCategoriesRequest,
): CancelablePromise<CreateWidgetCategoriesResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Categories/CreateWidgetCategory',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param request 
     * @returns ListWidgetCategoriesResponse Success
     * @throws ApiError
     */
    public static getApiV1CategoriesListWidgetCategories(
request?: ListWidgetCategoriesRequest,
): CancelablePromise<ListWidgetCategoriesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Categories/ListWidgetCategories',
            query: {
                'request': request,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns EditWIdgetCategoriesResponse Success
     * @throws ApiError
     */
    public static putApiV1CategoriesEditWidgetCategories(
requestBody?: EditWidgetCategoriesRequest,
): CancelablePromise<EditWIdgetCategoriesResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/Categories/EditWidgetCategories',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns EditCategoriesResponse Success
     * @throws ApiError
     */
    public static putApiV1CategoriesEditCategories(
requestBody?: EditCategoriesRequest,
): CancelablePromise<EditCategoriesResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/Categories/EditCategories',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns DeleteCategoriesResponse Success
     * @throws ApiError
     */
    public static deleteApiV1CategoriesDeleteCategories(
requestBody?: DeleteCategoriesRequest,
): CancelablePromise<DeleteCategoriesResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/Categories/DeleteCategories',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
