<template>
  <div class="flex flex-column m-2 gap-4">
    <div class="flex align-items-center gap-2" v-if="isAdmin">
      <label for="public-switch">Public</label>
      <InputSwitch id="public-switch" v-model="isPublic" />
      <FileUploader @selected="changeData" v-model="base64PreviewImage"></FileUploader>
      <WidgetCategorySelector @selected="changeWidgetCategories"/>
    </div>
    <ModelUpdater v-if="widget.type" :model="widget.model" :type="widget.type" @modelUpdated="updateModel" isWidget />
  </div>
  <div class="flex flex-row m-2 mt-5 justify-content-between">
    <Button label="Cancel" @click="onCancel()" />
    <Button label="Confirm" @click="dispatchUpdateWidgetAsync()" />
  </div>
</template>
<script setup lang="ts">
import InputSwitch from 'primevue/inputswitch';
import { FileUploader, ModelUpdater, WidgetCategorySelector } from '@/earth/components';
import Button from 'primevue/button';
import { WidgetType, type ObjectListWidgetsResponse_Widget as Widget, WidgetsService, type Link, type Embedded, CategoriesService } from '@/apis/earth-api';
import { ref } from 'vue';
import type { KeycloakService } from "@congatec/authentication-lib";
import { onKeycloakToken } from "@congatec/authentication-lib";

const props = defineProps<{
  widget: Widget
}>();

const emits = defineEmits(["onCancel", "onEdit"])
//general data
const widget = ref<Widget>(props.widget);
const categories = ref<string[]>([])
//admin data
const base64PreviewImage = ref<string>("");
const isPublic = ref<boolean>(widget.value.model.public);
const isAdmin = ref<boolean>(false);

onKeycloakToken(async (authenticationService: KeycloakService) => {
  isAdmin.value = authenticationService.hasRoles(["Admin", "SystemAdmin"],import.meta.env.VITE_KEYCLOAK_JUPITER_CLIENTID);
});

//functions
function changeData(newData: any) {
  const reader = new FileReader();
  reader.readAsBinaryString(newData)
  reader.onload = function () {
    base64PreviewImage.value = btoa(reader.result as string)
  }
}

function changeWidgetCategories(selectedCategories : string[]){
    categories.value = selectedCategories
}

async function dispatchUpdateWidgetAsync() {
  await WidgetsService.putApiV1WidgetsEdit({
    widgetId: props.widget.id,
    public: isPublic.value,
    base64PreviewImage: base64PreviewImage.value,
    model: widget.value.model,
  })

  await CategoriesService.putApiV1CategoriesEditWidgetCategories({
    widgetId: props.widget.id,
    categoryNames: categories.value
  })
  emits("onEdit")
}

function updateModel(model: Link | Embedded) {
  console.log('Model updated', model)

  widget.value.model.title = model.title;
  widget.value.model.subTitle = model.subtitle;
  widget.value.model.details = model.details;
  switch (widget.value.type) {
    case WidgetType.LINK: {
      if (('linkText' && 'linkUrl') in model) {
        console.log("Testlog123123")
        widget.value.model.linkText = model.linkText
        widget.value.model.linkUrl = model.linkUrl
      }
      break;
    } case WidgetType.EMBEDDED: {
      if ('url' in model) {
        widget.value.model.url = model.url;
      }
      break;
    }
  }
}

  function onCancel() {
    emits("onCancel")
  }
</script>