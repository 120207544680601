<template>
  <div>
    <BaseWidget class="h-full" v-bind="props" :type="WidgetType.JIRA_TIME_BOOKING"
      :widget-configuration-id="BaseWidget.props.widgetConfigurationId">
      <template #default>
        <div class="flex flex-column" v-if="tokenActive">
          <!-- <a target="_blank" class="flex align-items-center justify-content-center gap-2" v-if="model.linkUrl && model.linkUrl != null" :href="model.linkUrl">{{ model.linkText }}<i class="pi pi-external-link" /></a> -->
          <form v-on:submit.prevent class="flex flex-column">
            <InputText style="width: 100%" v-model="itemValue" type="text" placeholder="Jira Key (e.g. ITDODS-23)">
            </InputText>
            <Button style="width: 100%" label="Add" type="submit" icon="pi pi-plus" autofocus @click="addTask" />
          </form>

          <Listbox v-model="selectedIssue" :options="(model.issues as any)" option-label="name" class="flex flex-column">
            <template #option="{ option }">
              <div class="flex justify-content-between">
                <div>
                  <h3>
                    <a :href="jUrl.VITE_JIRA_BROWSE_URL + option.name" target="”_blank”">{{ option.name }}</a>
                  </h3>
                  <p>{{ option.summary }}</p>
                </div>
                <div style="display: table">
                  <span style="
                      display: table-cell;
                      vertical-align: middle;
                      margin-left: 1rem;
                      margin-right: 1rem;
                    ">{{
                      formatTime(
                        response.jiraTicketsElapsedTime
                          ? response.jiraTicketsElapsedTime[option.name] || 0
                          : 0
                      )
                    }}</span>
                </div>
                <div class="flex">
                  <Button style="margin-right: 5%; margin-left: 1rem; min-width:3rem !important;" icon="pi pi-play"
                    v-if="option.paused == true" @click="StartClick(option, jiraTokenStore.token)" />
                  <Button style="margin-right: 5%; margin-left: 1rem; min-width:3rem !important;" icon="pi pi-stop"
                    v-if="option.paused == false" @click="StopClick(option)" />
                  <Button icon="pi pi-calendar-plus" v-if="option.paused == true"
                    @click="CommitClick(option, jiraTokenStore.token)" style="min-width:3rem !important;" />
                  <Button icon="pi pi-pause" v-if="option.paused == false" @click="PauseClick(option)"
                    style="min-width:3rem !important;" />
                  <Button icon="pi pi-trash" @click="confirmDelete(option)"
                    style="margin-left: 5%; margin-right: 1rem; min-width:3rem !important;" />
                </div>
              </div>
            </template>
          </Listbox>
        </div>
        <div v-else>
          <!-- TODO: Make Jira URL configurable in .env -->
          <p style="color: var(--primary-color)">
            You currently do not have a valid Jira token
            <a href="https://one.congatec.local:8445/secure/ViewProfile.jspa" target="_blank">Get a Token here!</a>
          </p>
          <div>
            <InputText style="width: 80%" v-model="jiraToken" placeholder="Insert Jira Token"></InputText>
            <Button size="large" icon="pi pi-check" autofocus @click="addJiraToken" />
          </div>
        </div>
      </template>
      <!-- To pass down all slots to Widget -->
      <template v-for="(_, name) in slots" v-slot:[name]="scopedSlotData">
        <slot :name="name" v-bind="scopedSlotData"></slot>
      </template>
    </BaseWidget>
    <Dialog v-model:visible="loginFailed" modal header="Login failed" :style="{ width: '30vw' }">
      <p>
        Login failed your token seems to be invalid please try again or create a
        token here
        <!-- TODO: Make Jira URL configurable in .env -->
        <a href="https://one.congatec.local:8445/secure/ViewProfile.jspa" target="_blank">Get a Token here!</a>
      </p>
      <template #footer>
        <Button label="Confirm" icone="pi pi-check" @click="loginFailed = false" />
      </template>
    </Dialog>
    <Dialog v-model:visible="taskFailed" modal header="Issue doesn't exist" :style="{ width: '30vw' }">
      <p>
        This issue isn't valid! Please make sure you got it correct and try
        again!
      </p>
      <template #footer>
        <Button label="Confirm" icone="pi pi-check" @click="(taskFailed = false), (itemValue = '')" />
      </template>
    </Dialog>
  </div>
</template>
<script setup lang="ts">
import { type Ref, ref, toRef, useSlots, onMounted, onUnmounted } from "vue";
import {
  WidgetConfigurationsService,
  WidgetType,
  JiraService,
  type CheckTasksResponse,
  type JiraElapsedTimeResponse,
  type JiraTimeBooking
} from "@/apis/earth-api";
import Dialog from "primevue/dialog";
import { BaseWidget } from "@/earth/components";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { useJiraTokenStore } from "@/earth/store";
import Listbox from "primevue/listbox";
import { useConfirm } from 'primevue/useconfirm';

const confirm = useConfirm();
const jUrl = import.meta.env;
const request: Ref<{ jiraStoryKeys: string[] }> = ref({ jiraStoryKeys: [] });
const response: Ref<JiraElapsedTimeResponse> = ref({});
const props = withDefaults(
  defineProps<{
    model: JiraTimeBooking;
    testwidgetId: number;
  }>(),
  {
    model: () => {
      return { issues: undefined };
    },
  }
);

const emits = defineEmits<{ (e: 'commitJiraClick', jiraIssueNumber: string, widgetConfigurationId: number): void }>()
const jiraTokenStore = useJiraTokenStore();
const taskFailed = ref(false);
const loginFailed = ref(false);

const minuteInterval = setInterval(async () => {
  if (request.value.jiraStoryKeys != null && request.value != null) {
    console.log(request.value.jiraStoryKeys, " ---REQUESTLENGTH---");
    loadTimer()
  }
}, 60000);

const secondInterval = setInterval(async () => {
  if (request.value.jiraStoryKeys.length > 0 && request.value != null) {
    request.value.jiraStoryKeys.forEach(storyKey => {
      if (response.value.jiraTicketsElapsedTime != undefined) {
        if (response.value.jiraTicketsElapsedTime[storyKey] != null) {
          (response.value.jiraTicketsElapsedTime[storyKey] as number) += 1;
        }

      }

    });
  }
}, 1000);

const checkLoginInterval = setInterval(async () => {
  let checkLoginResponse = await dispatchCheckJiraLogin(jiraToken.value);
  if (checkLoginResponse == false) {
    jiraTokenStore.clear();
  }

}, 600000);

let model = toRef(props, "model");
let tokenActive = ref(false);
let checkedLogin: any;
let checkedTask: CheckTasksResponse;
let itemValue = ref("");
let jiraToken = ref(jiraTokenStore.token);
const slots = useSlots();

if (jiraToken.value !== undefined) {
  tokenActive.value = true;
}
const selectedIssue = ref();

onMounted(async () => {
  if (model.value.issues) {
    console.log('Issues:',model.value.issues)
    model.value.issues.forEach((element) => {
      if (!element.paused) {
        if (element.name) request.value.jiraStoryKeys?.push(element.name);
        console.log("Story Keys on mounted", request.value.jiraStoryKeys);
      }
    });
    loadTimer();
  }
  let checkLoginResponse = await dispatchCheckJiraLogin(jiraToken.value);
  if (checkLoginResponse == false) {
    jiraTokenStore.clear();
  }
}),
  onUnmounted(() => {
    clearInterval(minuteInterval);
    clearInterval(secondInterval);
    clearInterval(checkLoginInterval)
  }),
  jiraTokenStore.$subscribe(() => {
    if (jiraTokenStore.token !== "undefined") {
      setTokenActive();
    } else {
      resetTokenActive();
    }
  });

// The WidgetConfiguration update
//const updateWidgetConfigurationsRequest = ref<UpdateWidgetConfigurationsRequest>({});
async function dispatchUpdateWidgetConfigurationsAsync(r: any) {
  if (r) {
    console.log("request:", r);
    await WidgetConfigurationsService.putApiV1WidgetConfigurationsUpdate(r);
  }
}

async function dispatchCheckJiraLogin(jiraToken: any) {
  return (
    await JiraService.postApiV1JiraCheckLogin({ jiraToken: jiraToken })
  ).jiraSuccess;
}

async function dispatchCheckJiraTasks(jiraTaskNumber: any, jiraToken: any) {
  return await JiraService.postApiV1JiraCheckTasks({
    jiraToken: jiraToken,
    taskNumber: jiraTaskNumber,
  });
}

function resetTokenActive() {
  tokenActive.value = false;
}

function setTokenActive() {
  tokenActive.value = true;
}

async function StartClick(option: any, jiraToken: any) {
  await JiraService.postApiV1JiraStartTracking({
    taskNumber: option.name,
    jiraToken: jiraToken,
  });
  option.paused = false;
  let pauseIndex = model.value.issues?.findIndex(x => x.name === option.name) || 0;
  if (model.value.issues) {
    model.value.issues[pauseIndex].paused = false;
  }
  await dispatchUpdateWidgetConfigurationsAsync({
    model: model.value,
    WidgetConfigurationId: props.testwidgetId,
  });
  option.paused = false;
  request.value.jiraStoryKeys?.push(option.name);
  loadTimer()
}

async function loadTimer() {
  response.value = await JiraService.getApiV1JiraElapsedTime(
    request.value.jiraStoryKeys
  );
}

async function StopClick(option: any) {
  await JiraService.postApiV1JiraStopTracking({ taskNumber: option.name });
  option.paused = true;
  let pauseIndex = model.value.issues?.findIndex(x => x.name === option.name) || 0;
  if (model.value.issues) {
    model.value.issues[pauseIndex].paused = true;
  }
  await dispatchUpdateWidgetConfigurationsAsync({
    model: model.value,
    WidgetConfigurationId: props.testwidgetId,
  });

  console.log(request.value.jiraStoryKeys);
  var index = request.value.jiraStoryKeys?.findIndex(() => option.name);
  if (index != undefined)
    request.value.jiraStoryKeys = request.value.jiraStoryKeys?.splice(1, index);
}

async function PauseClick(option: any) {
  await JiraService.postApiV1JiraPauseTracking({ taskNumber: option.name });
  option.paused = true;
  let pauseIndex = model.value.issues?.findIndex(x => x.name === option.name) || 0;
  if (model.value.issues) {
    model.value.issues[pauseIndex].paused = true;
  }
  await dispatchUpdateWidgetConfigurationsAsync({
    model: model.value,
    WidgetConfigurationId: props.testwidgetId,
  });

  console.log(request.value.jiraStoryKeys);
  var index = request.value.jiraStoryKeys?.findIndex(() => option.name);
  if (index != undefined)
    request.value.jiraStoryKeys = request.value.jiraStoryKeys?.splice(1, index);
}

async function CommitClick(option: any, jiraToken: any) {
  console.log("testWidgetId:", props.testwidgetId)
  emits("commitJiraClick", option.name, props.testwidgetId)
  // const comment = "I worked on this issue " + option.name;

  // removeItem(selectedIssue.value);
  // var index = request.value.jiraStoryKeys?.findIndex(() => option.name);
  // if (index != undefined)
  //   request.value.jiraStoryKeys = request.value.jiraStoryKeys?.splice(index, 1);
}

function formatTime(elapsedTime: number) {
  const totalMinutes = Math.floor(elapsedTime / 60);

  const seconds = `0${elapsedTime % 60}`.slice(-2);
  const hours = `0${Math.floor(totalMinutes / 60)}`.slice(-2);
  const minutes = `0${totalMinutes % 60}`.slice(-2);

  return `${hours}:${minutes}:${seconds}`;
}

const confirmDelete = (item: any) => {
  confirm.require({
    message: 'No time trackings will be commited to Jira, but they are available again when the same task is added to the time tracking widget.',
    header: 'Remove Task',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-info',
    accept: async () => {
      removeItem(item)
    },
    reject: async () => {
      // toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }
  });
}

async function removeItem(item: any) {
  const index = model.value.issues?.indexOf(item);
  if (index !== undefined && index > -1) {
    model.value.issues?.splice(index, 1);
    await dispatchUpdateWidgetConfigurationsAsync({
      model: model.value,
      WidgetConfigurationId: props.testwidgetId,
    });
  }
}

const addJiraToken = async () => {
  checkedLogin = await dispatchCheckJiraLogin(jiraToken.value);
  if (checkedLogin === true) {
    jiraTokenStore.set(jiraToken.value || "");
  } else {
    loginFailed.value = true;
  }
};

const addTask = async () => {
  itemValue.value = itemValue.value.trim();
  checkedTask = await dispatchCheckJiraTasks(
    itemValue.value,
    jiraTokenStore.token
  );
  if (itemValue.value.length !== 0 && checkedTask.taskExists) {
    if (model.value.issues?.values === undefined) {
      model.value.issues = [];
    }
    model.value.issues?.push({
      name: itemValue.value,
      paused: true,
      summary: checkedTask.taskSummary || "",
    });
    dispatchUpdateWidgetConfigurationsAsync({
      model: model.value,
      WidgetConfigurationId: props.testwidgetId,
    });

    itemValue.value = "";
  } else {
    taskFailed.value = true;
  }
};

jiraTokenStore.$subscribe(() => {
  if (jiraTokenStore.token == undefined) {
    console.log("JiraLogout!")
    tokenActive.value = false;
    jiraToken.value = undefined
  }
})

</script>
