import { ManageUsers } from '@/ceres/components/Users';
import { ManageCompanies } from '@/ceres/components/Companies';
import { to } from '@congatec/primevue-components-lib';
const neptuneRoutes = [
  {
    path: '/ceres/users',
    name: 'CeresManageUsers',
    component: ManageUsers,
    meta: {
      breadcrumb: [{ title: "Ceres" }, { label: "Users" }],
    },
  },
  {
    path: '/ceres/companies',
    name: 'CeresManageCompanies',
    component: ManageCompanies,
    meta: {
      breadcrumb: [{ title: "Ceres" },{ label: "Ceres managa Users",command:to("/ceres/users") }, { label: "Companies" }],
    },
  },
];


export default neptuneRoutes;

