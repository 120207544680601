
<template>
  <Toast position="top-right" />
  <ConfirmDialog />
  <div class="flex flex-column gap-1 m-2">
    <div class="flex flex-1">
      <MarketplaceToolbar :category-created="updateCategories"
        :category-edited="updateCategories" class="flex-1" :on-search-term-changed="(t) => {
        }" @widgetCreated="loadWidgetData"/>
    </div>
    <template :key="category" v-for="category in Object.keys(widgetCategoriesWidgetList)">
      <div class="flex flex-column">
        <h3 style="color: var(--primary-color)">{{ category }}</h3>
        <Divider />
        <div class="flex flex-row flex-1 align-items-stretch gap-2 flex-wrap widgets-marketplace-view">
          <span v-for="(w, i) of widgetCategoriesWidgetList[category]" :key="i">
            <span>
              <MarketplaceWidget style="min-width: 350px; max-width: 350px;" :type="widgets[w].type"
                :model="widgets[w].model" :preview-img="widgets[w].base64PreviewImage || ''"
                selectedWidget="">
                <template #actions>
                  <div class="flex flex-row w-full align-items-center">
                    <WidgetConfigurationsCreateButton :widgetId="widgets[w].id"
                      @click="showSuccess(widgets[w].model)" />
                    <Button icon="pi pi-ellipsis-v" label="" class="p-button-sm p-button-rounded p-button-text"
                      @click="(e) => { toggleMenu(e); selectedWidget = widgets[w] }" aria-haspopup="true"
                      aria-controls="overlay_menu" />
                    <Menu ref="menu" id="overlay_menu" :model="menuItems" :popup="true" />
                  </div>
                </template>
              </MarketplaceWidget>
            </span>
          </span>

        </div>
      </div>
    </template>
  </div>
  <ConPopupDialog  title="Edit Widget" @onHide="showEditDialog=false"
    :visible="showEditDialog" :draggable="false" style="max-width: 90%; min-width: 60%;max-height: 90%;" >
    <EditWidgetView v-if="selectedWidget" :widget="selectedWidget" @onEdit="showEditDialog=false; loadWidgetData()" @onCancel="showEditDialog=false">
    </EditWidgetView>
  </ConPopupDialog>

  <Dialog v-if="showDetailsDialog && selectedWidget" modal position="top" header="Widget Details"
    v-model:visible="showDetailsDialog" maximizable style="height:100%;width:80%">
    <WidgetDetailsView v-if="selectedWidget" :widget="selectedWidget">
    </WidgetDetailsView>
  </Dialog>
</template>
<script setup lang="ts">
import {  ref  } from 'vue';
import { MarketplaceToolbar, MarketplaceWidget, WidgetDetailsView, WidgetConfigurationsCreateButton, EditWidgetView } from '@/earth/components';
import { useToast } from 'primevue/usetoast'
import { WidgetsService, type ObjectListWidgetsResponse_Widget as Widgets  } from '@/apis/earth-api';
import Toast from "primevue/toast"
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Menu from 'primevue/menu';
import ConfirmDialog from 'primevue/confirmdialog';
import { useConfirm } from 'primevue/useconfirm';
import Divider from 'primevue/divider';
import type { KeycloakService } from "@congatec/authentication-lib";
import { onKeycloakToken } from "@congatec/authentication-lib";
import { ConPopupDialog } from '@congatec/primevue-components-lib';


const toast = useToast()
const widgets = ref<Widgets[]>([]);
let selectedWidget: Widgets;
const showEditDialog = ref<boolean>(false);
const confirm = useConfirm();
const menu = ref();
const showDetailsDialog = ref<boolean>(false);
const menuItems = ref([
  {
    label: 'Edit',
    icon: 'pi pi-pencil',
    command: () => {
      showEditDialog.value = true;
    }
  },
  {
    label: 'Delete',
    icon: 'pi pi-times',
    command: () => {
      confirmDelete();
    }
  },
  {
    label: 'Details',
    icon: 'pi pi-eye',
    command: () => {
      showDetailsDialog.value = true;
      console.log("selectedWidget:", selectedWidget);
    }
  }
]);

const widgetCategoriesWidgetList = ref<{ [key: string]: number[] }>({})
const isAdmin = ref(false);

onKeycloakToken(async (authenticationService: KeycloakService) => {
  isAdmin.value = authenticationService.hasRoles(["Admin", "SystemAdmin"],import.meta.env.VITE_KEYCLOAK_JUPITER_CLIENTID);
  loadWidgetData();
});

async function loadWidgetCategoryData() {
  widgetCategoriesWidgetList.value = {}
  widgets.value.forEach((widgets, index) => {

    widgets.categoryList?.forEach((category : any) => {
      if (widgetCategoriesWidgetList.value[category]) {
        widgetCategoriesWidgetList.value[category].push(index)
      } else {
        widgetCategoriesWidgetList.value[category] = [index]
      }
    });
  });
  widgetCategoriesWidgetList.value['All Widgets'] = []
  widgets.value.forEach((widgets, index) => {
    widgetCategoriesWidgetList.value['All Widgets'].push(index)
  })
}

async function loadWidgetData(){
  console.log("is this doing something?")
  widgets.value = (await WidgetsService.getApiV1WidgetsList(false, 1, 10000, 0)).widgets || [];
  await loadWidgetCategoryData();
}

async function updateCategories() {
  console.log("Update Categories!")
  await loadWidgetData();
  await loadWidgetCategoryData();
}

console.log("WIDGETS: ", widgets.value);

function showSuccess(widgetModel: any) {

  let widgetTitle = ''
  console.log("WidgetModel: ", widgetModel)
  for (const key in widgetModel) {
    if (key == 'title') {
      widgetTitle = widgetModel[key];
    }
  }
  toast.add({ severity: 'success', summary: 'Widget added to Dashboard!', detail: 'The Widget "' + widgetTitle + '" has successfully been added to your congatec Dashboard', life: 3000 });
}

const toggleMenu = (event: any) => {
  menu.value[0].toggle(event);
};

const confirmDelete = () => {
  confirm.require({
    message: 'Do you want to delete the Widget "' + selectedWidget.model.title + '"?',
    header: 'Delete Confirmation',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-info',
    accept: async () => {
      try {
        await WidgetsService.deleteApiV1WidgetsDelete(selectedWidget.id)
        toast.add({ severity: 'success', summary: 'Delete', detail: 'Widget Deleted', life: 3000 });
        loadWidgetData();
      }
      catch (ex: any) {
        toast.add({ severity: 'error', summary: 'Error', detail: 'An error occured while deleting the pool: ' + ex.message, life: 3000 });
      }
    },
    reject: async () => {
      // toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }
  });
}

</script>