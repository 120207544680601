<script setup lang="ts">
import Button from "primevue/button";

const emit = defineEmits(["selected"])
function chooseFiles(){
    document.getElementById("fileUpload")?.click()
}

function onFileChanged (e:any) {
    const file = e.target.files[0];
    emit('selected',file)
}
</script>
<template>
    <input id="fileUpload" type="file" hidden @change="onFileChanged"/>
    <Button @click="chooseFiles()" >
        Select Image
    </Button>
</template>