/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckLoginRequest } from '../models/CheckLoginRequest';
import type { CheckLoginResponse } from '../models/CheckLoginResponse';
import type { CheckTasksRequest } from '../models/CheckTasksRequest';
import type { CheckTasksResponse } from '../models/CheckTasksResponse';
import type { CommitTrackingRequest } from '../models/CommitTrackingRequest';
import type { CommitTrackingResponse } from '../models/CommitTrackingResponse';
import type { DeleteTrackingRequest } from '../models/DeleteTrackingRequest';
import type { DeleteTrackingResponse } from '../models/DeleteTrackingResponse';
import type { EditTrackingRequest } from '../models/EditTrackingRequest';
import type { EditTrackingResponse } from '../models/EditTrackingResponse';
import type { JiraElapsedTimeResponse } from '../models/JiraElapsedTimeResponse';
import type { ListTrackingResponse } from '../models/ListTrackingResponse';
import type { PauseTrackingRequest } from '../models/PauseTrackingRequest';
import type { PauseTrackingResponse } from '../models/PauseTrackingResponse';
import type { StartTrackingRequest } from '../models/StartTrackingRequest';
import type { StartTrackingResponse } from '../models/StartTrackingResponse';
import type { StopTrackingRequest } from '../models/StopTrackingRequest';
import type { StopTrackingResponse } from '../models/StopTrackingResponse';
import type { SummarizeTrackingRequest } from '../models/SummarizeTrackingRequest';
import type { SummarizeTrackingResponse } from '../models/SummarizeTrackingResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class JiraService {

    /**
     * @param requestBody 
     * @returns CheckLoginResponse Success
     * @throws ApiError
     */
    public static postApiV1JiraCheckLogin(
requestBody?: CheckLoginRequest,
): CancelablePromise<CheckLoginResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Jira/CheckLogin',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns StartTrackingResponse Success
     * @throws ApiError
     */
    public static postApiV1JiraStartTracking(
requestBody?: StartTrackingRequest,
): CancelablePromise<StartTrackingResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Jira/StartTracking',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns PauseTrackingResponse Success
     * @throws ApiError
     */
    public static postApiV1JiraPauseTracking(
requestBody?: PauseTrackingRequest,
): CancelablePromise<PauseTrackingResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Jira/PauseTracking',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns StopTrackingResponse Success
     * @throws ApiError
     */
    public static postApiV1JiraStopTracking(
requestBody?: StopTrackingRequest,
): CancelablePromise<StopTrackingResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Jira/StopTracking',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns CommitTrackingResponse Success
     * @throws ApiError
     */
    public static postApiV1JiraCommitTracking(
requestBody?: CommitTrackingRequest,
): CancelablePromise<CommitTrackingResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Jira/CommitTracking',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns CheckTasksResponse Success
     * @throws ApiError
     */
    public static postApiV1JiraCheckTasks(
requestBody?: CheckTasksRequest,
): CancelablePromise<CheckTasksResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Jira/CheckTasks',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param jiraStoryKeys 
     * @returns JiraElapsedTimeResponse Success
     * @throws ApiError
     */
    public static getApiV1JiraElapsedTime(
jiraStoryKeys?: Array<string>,
): CancelablePromise<JiraElapsedTimeResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Jira/ElapsedTime',
            query: {
                'JiraStoryKeys': jiraStoryKeys,
            },
        });
    }

    /**
     * @param taskNumber 
     * @returns ListTrackingResponse Success
     * @throws ApiError
     */
    public static getApiV1JiraListTimeTracking(
taskNumber?: string,
): CancelablePromise<ListTrackingResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Jira/ListTimeTracking',
            query: {
                'TaskNumber': taskNumber,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns DeleteTrackingResponse Success
     * @throws ApiError
     */
    public static deleteApiV1JiraDeleteTracking(
requestBody?: DeleteTrackingRequest,
): CancelablePromise<DeleteTrackingResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/Jira/DeleteTracking',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns SummarizeTrackingResponse Success
     * @throws ApiError
     */
    public static postApiV1JiraSummarizeTracking(
requestBody?: SummarizeTrackingRequest,
): CancelablePromise<SummarizeTrackingResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Jira/SummarizeTracking',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns EditTrackingResponse Success
     * @throws ApiError
     */
    public static putApiV1JiraEditTimeTracking(
requestBody?: EditTrackingRequest,
): CancelablePromise<EditTrackingResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/Jira/EditTimeTracking',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
