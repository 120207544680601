import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'
import { type ListTabsResponse_Tab } from "@/apis/earth-api";
import { ref } from 'vue';


export const useTabsStore = defineStore("TabsStore", () => {
  const tabs = ref<ListTabsResponse_Tab[] | undefined | null>([]);
  const dirtyCount = ref(0);


  // this acts as a signal to other tbas 
  // to inform them that their rendering state 
  // has been changed
  // TODO: Move this to widget configuration store 
  // once we migrated to pinia 
  const dirty = () => {
    dirtyCount.value++;
  }

  const set = (newTab: ListTabsResponse_Tab, index: number) => {
    if (tabs.value) {
      tabs.value[index] = newTab;
    }
  }

  const newTab = (id: number) => {
    tabs.value?.push({ id })
  }

  const load = (newTabs: ListTabsResponse_Tab[]) => {
    tabs.value = newTabs;
  }

  const remove = (index: number) => {
    tabs.value?.splice(index, 1);
  }


  return { tabs, set, remove, load, newTab, dirty, dirtyCount }
});
