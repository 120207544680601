<script setup lang="ts">
import Panel from "primevue/panel";
import { useSlots } from "vue";
import { WidgetType, type Link } from "@/apis/earth-api";


withDefaults(defineProps<{
  type?: WidgetType,
  model: Link,
  headerIsToggled?: boolean,
}>(), {
  preview: false,
  type: undefined,
  headerIsToggled: false,
});

const slots = useSlots();
</script>
<template>
  <Panel class="con-widget-panel flex flex-column mt-0 pt-0 flex-1 p-card overflow-auto h-full">
    <!-- Widget Header  -->
    <template #header>
      <div class="flex flex-row w-full align-items-center justify-content-end h-full">
        <div class="flex flex-column align-items-center w-full h-full" v-if="headerIsToggled == false">
          <div class="justify-items-center font-bold text-xl">{{ model.title }}</div>
          <div class="justify-items-center p-card-subtitle text-sm"> {{ model.subtitle }}</div>
        </div>
        <div class="flex  align-items-end">
          <slot v-if="slots['con-actions']" name="con-actions" :scopedSlotData="model"></slot>
        </div>
      </div>

    </template>

    <!-- Widget Content-->
    <!--   @vue-ignore  -->
    <template #default="slotProps">
      <div class="con-widget-content flex flex-1 flex-column gap-5 h-full">
        <div class="flex-1 w-full h-full">
          <slot :scopedSlotData="model" v-bind="(slotProps as any)"></slot>
        </div>
      </div>
    </template>
  </Panel>
</template>
<style lang="scss" scoped>
.con-widget-panel {
  border: 1px solid var(--primary-color);


}

.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  height: 1.5rem !important;
}

.p-button.p-button-icon-only {
  width: 1.5rem !important;
  padding: 0.714rem;
}
</style>
<style>
  .p-panel-header {
    margin: 0;
    padding: 0 !important;
  }

  .p-toggleable-content {
    height: 100%;
  }

  .p-panel-content {
      display: flex;
      height: 100%;
    }
</style>