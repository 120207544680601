<template>
  <Toast position="top-right" />
  <ConfirmDialog />
  <JiraTimeBookingEditDialog :jiraTaskNumber="jiraTaskNumber" :widgetConfigId="widgetConfigJiraId"  v-if="showJiraTimeBookingEdit"
    :visible="showJiraTimeBookingEdit"  @jiraIssueCommitedAndRemoved="loadDahsboardAfterIssueDelete" :onHide="(_edited: boolean) => {
      showJiraTimeBookingEdit = false;
    }" />
  <div class="flex-1 h-full">
    <GridLayout :key="gridInvalid"
      v-if="widgetConfigurations && widgetConfigurations.length > 0 && gridLayouts && widgetsRendered > 0"
      :layout="gridLayouts" preventCollision class="flex-1" :use-css-transforms="true" :is-bounded="true" :colNum="12">
      <template #default="{ gridItemProps }">
        <template v-for="(l, i) of gridLayouts" :key="`${widgetConfigurations[l.i].widgetConfigurationId}-${i}`">
          <div v-if="widgetConfigurations[l.i].tabId == tabId && !ignoreWidget[l.i]">
             <!-- @vue-expect-error @move and @resize are lacking type information -->
            <GridItem class="flex widgets-dashboard-grid-item flex-column flex-1" is-draggable :x="l?.x" :y="l?.y"
              :h="l?.h" :w="l?.w" :i="l?.i" resizableHandleClass="align-self-end justify-self-end widget-expander"
              is-resizable 
              @move="(i:number, x:number, y:number) => onMove(widgetConfigurations[l.i], { i, x, y })"
              @resize="(i:number, h:number, w:number, newHeight:number, newWidth:number) => onResize(widgetConfigurations[l.i], { i, h, w, newHeight, newWidth })"
              @moved="dispatchUpdateWidgetConfigurationsAsync(widgetConfigurations[l.i])"
              @resized="dispatchUpdateWidgetConfigurationsAsync(widgetConfigurations[l.i])" v-bind="gridItemProps"
              :min-h="widgetConfigurations[l.i]?.widget?.rowSpan" :min-w="widgetConfigurations[l.i]?.widget?.columnSpan"
              :responsive="true" style="border-radius: 4px;">
              <div class="flex-1 flex" @contextmenu="onWidgetRightClick($event, widgetConfigurations[l.i])"
                aria-haspopup="true">
                <ContextMenu ref="widgetMenu" :model="widgetMenuItems" />
                <!-- @vue-expect-error component does not have type info... -->
                <component :headerIsToggled="widgetConfigurations[l.i].isToggled"
                  :testwidgetId="widgetConfigurations[l.i].widgetConfigurationId" class="flex-1" 
                  style="{ transform: none !important; }" :is="WidgetConfigMapper[widgetConfigurations[l.i].widget?.type!]"
                  v-bind:model="widgetConfigurations[l.i].model" @commitJiraClick="openEditBeforeCommit" >
                  <!-- <h1 style="color: purple;">{{ widgetConfigurations[l.i].widget?.type!.toLowerCase() }}</h1> -->
                  <template #con-actions>
                    <Button icon="pi pi-ellipsis-v" label="" class="p-button-sm p-button-rounded p-button-text"
                      @click="(e) => { toggleMenu(e, i); onWidgetRightClick(undefined, widgetConfigurations[l.i]); selectedWidgetConfiguration = widgetConfigurations[l.i]; }"
                      aria-haspopup="true" aria-controls="overlay_menu" />
                    <TieredMenu ref="menu" :model="menuItems" :popup="true" />
                  </template>
                </component>
              </div>
            </GridItem>
          </div>
        </template>

      </template>

    </GridLayout>
    <div v-else style="color: var(--primary-color);" class="flex align-items-center flex-1 flex-column h-full p-5">
      <span>No widgets in your dashboard!</span>
      <span>Go to the <RouterLink to="marketplace"><i class="pi pi-shopping-cart" /> Marketplace</RouterLink> and
        add some.</span>
    </div>
    <ConPopupDialog  title="Edit Widget" @onHide="showEditDialog=false"
    :visible="showEditDialog" :draggable="false" style="max-width: 90%; min-width: 60%;max-height: 90%;" >
    <EditWidgetConfigurationsView v-if="selectedWidgetConfiguration" :widgetConfiguration="selectedWidgetConfiguration" @onEdit="showEditDialog=false; " @onCancel="showEditDialog=false">
    </EditWidgetConfigurationsView>
  </ConPopupDialog>
  </div>

  <Dialog v-if="showSpotlightDialog && selectedWidgetConfiguration" modal position="top" header="Spotlight"
    v-model:visible="showSpotlightDialog" maximizable style="height:100%;width:80%">
    <SpotLightView v-if="selectedWidgetConfiguration" :widget-configuration="selectedWidgetConfiguration">

    </SpotLightView>
  </Dialog>
</template>
<script setup lang="ts">
import { 
  type ListUsersWidgetConfigurationsResponse_WidgetConfiguration as WidgetConfigurations, 
  type UpdateWidgetConfigurationsRequest, 
  WidgetConfigurationsService, 
  UsersService,
} from "@/apis/earth-api";
import { JiraTimeBookingEditDialog, SpotLightView, LinkWidget, EmbeddedWidget, JiraTimeBookingWidget, EditWidgetConfigurationsView} from "@/earth/components";
import { computed, ref, watch } from "vue";
import { GridLayout, GridItem } from "vue3-drr-grid-layout";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import TieredMenu from "primevue/tieredmenu";
import 'vue3-drr-grid-layout/dist/style.css';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import Toast from 'primevue/toast';
import ConfirmDialog from 'primevue/confirmdialog';
import { useJiraTokenStore } from '@/earth/store'
import ContextMenu from 'primevue/contextmenu';
import { useTabsStore } from "@/earth/store/tabsStore";
import { onKeycloakToken, type KeycloakService } from "@congatec/authentication-lib";
import { ConPopupDialog } from "@congatec/primevue-components-lib";
const jiraTokenStore = useJiraTokenStore();

export type DashboardGridLayout = {
  x: number,
  y: number,
  w: number,
  h: number,
  i: number
}

const props = withDefaults(
  defineProps<{
    rowHeight?: number,
    tabId?: number | null
  }>(),
  {
    rowHeight: 125,
  }
);

const toast = useToast()
const widgetConfigurations = ref<WidgetConfigurations[]>([]);

const WidgetConfigMapper = {'embedded' : EmbeddedWidget, 'link' : LinkWidget, 'jiraTimeBooking' : JiraTimeBookingWidget}
console.log("Map",WidgetConfigMapper)

const gridInvalid = ref(0);
const rightClickedWidgetConfiguration = ref<WidgetConfigurations | null>(null);
const widgetMenu = ref();
const widgetMenuItems = ref<any[]>([]);
const tabsStore = useTabsStore();
const ignoreWidget = ref<number[]>([]);

const menuIndexMap = ref<any>({});
const nextMenuIndex = ref(0);

const showJiraTimeBookingEdit = ref<boolean>(false);
const jiraTaskNumber = ref<string>("");
const widgetConfigJiraId = ref<number>(0);
const widgetsRendered = ref(0);
const selectedWidgetConfiguration = ref<WidgetConfigurations>();
const showEditDialog = ref<boolean>(false);
const showSpotlightDialog = ref<boolean>(false);
const gridLayouts = ref<DashboardGridLayout[]>([]);
const confirm = useConfirm();
const menu = ref();
const menuItems = ref([
  {
    label: 'Edit',
    icon: 'pi pi-pencil',
    command: () => {
      showEditDialog.value = true;
    }
  },
  {
    label: 'Delete',
    icon: 'pi pi-times',
    command: () => {
      confirmDelete();
    }

  },
  {
    label: 'Spotlight',
    icon: 'pi pi-window-maximize',
    command: () => {
      showSpotlightDialog.value = true;
    },
    visible: computed(() => {
      return selectedWidgetConfiguration.value?.widget?.type?.toLowerCase() === 'embedded';
    }),
  },
  {
    label: 'Jira Logout',
    icon: 'pi pi-sign-out',
    command: () => {
      jiraTokenStore.clear()
    },
    visible: computed(() => {
      return selectedWidgetConfiguration.value?.widget?.type?.toLowerCase() === 'jiratimebooking';
    })
  },
  {
    label: 'Header Toggle',
    icon: 'pi-arrows-v',
    command: () => {
      widgetHeaderToggle()
    }
  },
  {
    key: 'sendToTab',
    label: 'Send to...',
    items: [] as any[]
  }
]);

onKeycloakToken(async (authenticationService: KeycloakService) => {
  console.log("K", authenticationService)
  await loadWidgetConfigurationData();
   invalidateGrid();
   loadGrid(widgetConfigurations.value, []);
   loadWidgetMenuItems();
});

async function loadWidgetConfigurationData(){
  widgetConfigurations.value = ((await UsersService.getApiV1UsersWidgetConfigurations(1,1,100000,0)).widgetConfigurations || []);  
}

tabsStore.$subscribe(() => {
  loadWidgetMenuItems();
});

const sendWidgetTo = async (tabId?: number | null) => {
  if (!rightClickedWidgetConfiguration.value) {
    return;
  }
  console.debug(`Sending widget ${rightClickedWidgetConfiguration.value.widgetConfigurationId} to tab with id ${tabId}`);
  rightClickedWidgetConfiguration.value.tabId = tabId || null;
  countRenderedWidgets();
  await dispatchUpdateWidgetConfigurationsAsync(rightClickedWidgetConfiguration.value);
  tabsStore.dirty();
}

const loadWidgetMenuItems = () => {
  widgetMenuItems.value = [];
  widgetMenuItems.value.push({ label: `Send to Home`, command: async () => await sendWidgetTo(null) });
  for (let tab of tabsStore.tabs || []) {
    widgetMenuItems.value.push({ label: `Send to ${tab.name || 'Untitled'}`, command: async () => await sendWidgetTo(tab.id) });
  }

  for (let item of menuItems.value) {
    if (item.key == 'sendToTab') {
      item.items = widgetMenuItems.value;
    }
  }
}

function loadGrid(nv: WidgetConfigurations[], prev: WidgetConfigurations[]) {
  console.log("WidgetCon: ", nv, "Previous: ", prev)

  // only re-load the grid if the layout has is null  
  // this prevents unexpected re-draws of the entire grid 
  if (nv != undefined && (!gridLayouts.value || gridLayouts.value.length == 0)) {
    // FIXME: Sometimes widget configuration ids appear twice 
    // This filter will remove them but this *must* have a deeper issue 
    ignoreWidget.value = [];
    let flags: number[] = [];
    nv.forEach((x, index) => {
      if (flags[x.widgetConfigurationId || 0]) {
        ignoreWidget.value[index] = index;
      }
      flags[x.widgetConfigurationId || 0] = x.widgetConfigurationId || 0;
      return x;
    });
    countRenderedWidgets();
    console.log("Filtered WidgetCon", nv);
    gridLayouts.value = nv.map(mapWidgetLayoutToGridLayout);
  }
}

const countRenderedWidgets = () => {
  widgetsRendered.value = 0;
  widgetConfigurations.value.forEach((x, index) => {
    if (
      x.tabId == props.tabId && !ignoreWidget.value[index]) {
      widgetsRendered.value++;
    }
  })
}

// call this function to invalidate the 
// current grid and force a redraw 
function invalidateGrid() {
  console.log("Invalidating grid layout");
  gridLayouts.value = [];
  gridInvalid.value += 1;
}

const toggleMenu = (event: any, index: number) => {
  let mappedIndex = 0;
  if (menuIndexMap.value[index] !== undefined) {
    mappedIndex = menuIndexMap.value[index];
  } else {
    mappedIndex = nextMenuIndex.value;
    nextMenuIndex.value++;
    menuIndexMap.value[index] = mappedIndex;
  }

  menu.value[mappedIndex].toggle(event);
};

const confirmDelete = () => {
  confirm.require({
    message: 'Do you want to delete the Widget "' + selectedWidgetConfiguration.value?.model.title + '"?',
    header: 'Delete Confirmation',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-info',
    accept: async () => {
      try {
        let index = widgetConfigurations.value.findIndex(widgetConfiguration => {
          return widgetConfiguration.widgetConfigurationId == selectedWidgetConfiguration.value?.widgetConfigurationId
        });

        await WidgetConfigurationsService.deleteApiV1WidgetConfigurationsDelete({
          widgetConfigurationId: selectedWidgetConfiguration.value?.widgetConfigurationId
        });

        let deletedWidgetConfiguration = widgetConfigurations.value[index];
        console.info('Deleting ', deletedWidgetConfiguration);
        removeItem(deletedWidgetConfiguration)
        toast.add({ severity: 'success', summary: 'Delete', detail: 'WidgetConfiguration Deleted', life: 3000 });
      }
      catch (ex: any) {
        toast.add({ severity: 'error', summary: 'Error', detail: 'An error occured while deleting the pool: ' + ex.message, life: 3000 });
      }
    },
    reject: async () => {
      // toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }
  });
}


watch(() => widgetConfigurations.value,
  loadGrid,
  { deep: true });

function mapWidgetLayoutToGridLayout(wc: WidgetConfigurations, i: number): DashboardGridLayout {
  var gridLayout: DashboardGridLayout;
  var { column, columnSpan, row, rowSpan } = wc;

  // evil hack to only render the widgets we want on this tab 
  // without breaking the assumption that the index of the gird layout and 
  // widget store match 
  // FIXME: The assumption that the index of the grid layout and widget store match is not 
  // that great to begin with. We should refactor this!
  if (ignoreWidget.value[i] || wc.tabId != props.tabId) {
    return { x: -100, y: -100, w: 0, h: 0, i: i };
  }
  gridLayout = {
    x: column ?? 0,
    y: row ?? 0,
    w: columnSpan ?? 0,
    h: rowSpan ?? 0,
    i: i,
  }

  console.log("LAYOUT: ", gridLayout);
  return gridLayout;
}

function onResize(wc: WidgetConfigurations, e: { i: number, h: number, w: number, newHeight: number, newWidth: number }) {
  console.log("RESIZED WIDGET: ", wc);
  console.log("RESIZED LAYOUT: ", e);
  wc.rowSpan = e.h;
  wc.columnSpan = e.w;
}

function onMove(wc: WidgetConfigurations, e: { i: number, x: number, y: number }) {
  const { x, y } = e;
  wc.column = x;
  wc.row = y;
}

// The WidgetConfiguration update
async function dispatchUpdateWidgetConfigurationsAsync(r: UpdateWidgetConfigurationsRequest, editViweUsed: boolean = false) {
  if (r) {
    await WidgetConfigurationsService.putApiV1WidgetConfigurationsUpdate(r);
    if (editViweUsed == true) {
      let index = widgetConfigurations.value.findIndex(widgetConfiguration => {
        return widgetConfiguration.widgetConfigurationId == r.widgetConfigurationId
      });
      console.log("Index: ", widgetConfigurations.value[index].model)
      widgetConfigurations.value[index].model = r.model;
    }
  }
}

async function removeItem(val: any) {
  console.log("Current WidgetConfigurations after delete: ", widgetConfigurations.value)
  const index = gridLayouts.value.map(item => item.i).indexOf(val);
  gridLayouts.value.splice(index, 1);

  invalidateGrid();
  widgetConfigurations.value = widgetConfigurations.value.filter(x => x.widgetConfigurationId != val.widgetConfigurationId);

  loadGrid(widgetConfigurations.value, []);
}

async function widgetHeaderToggle() {
  const index = widgetConfigurations.value.findIndex(widgetConfiguration => {
    return widgetConfiguration.widgetConfigurationId == selectedWidgetConfiguration.value?.widgetConfigurationId
  })

  widgetConfigurations.value[index].isToggled = !widgetConfigurations.value[index].isToggled;
  await dispatchUpdateWidgetConfigurationsAsync(widgetConfigurations.value[index])
}

const onWidgetRightClick = (event: any, widgetConfiguration: WidgetConfigurations) => {
  if (event) {
    widgetMenu.value[0].show(event);
  }
  rightClickedWidgetConfiguration.value = widgetConfiguration;
}

function openEditBeforeCommit(jiraIssueNumber: string, widgetConfigurationId:number) {
  widgetConfigJiraId.value = widgetConfigurationId;

  jiraTaskNumber.value = jiraIssueNumber;
  showJiraTimeBookingEdit.value = true
}

async function loadDahsboardAfterIssueDelete(){
  loadWidgetConfigurationData();
}
</script>
<style lang="scss">
.vue-grid-item>.vue-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  z-index: 20;
  bottom: 0;
  right: 0;
  background-image: url('/public/resizeHandle.png'); //url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08c3ZnIGlkPSJVbnRpdGxlZC1QYWdlJTIwMSIgdmlld0JveD0iMCAwIDYgNiIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6I2ZmZmZmZjAwIiB2ZXJzaW9uPSIxLjEiDQl4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIg0JeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ig0+DQk8ZyBvcGFjaXR5PSIwLjMwMiI+DQkJPHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz4NCTwvZz4NPC9zdmc+);
  background-position: bottom right;
  background-repeat: no-repeat;
  padding: 0 3px 3px 0;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}
</style>
