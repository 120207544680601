<script setup lang="ts">
import { CategoriesService, type ListCategoriesResponse } from '@/apis/earth-api';
import MultiSelect from 'primevue/multiselect';
import { onMounted, ref, watchEffect } from 'vue';
import Button from 'primevue/button';

const categories = ref<string[]>([]) 
const selectedCategories = ref<string[]>([]);

const selectedCategoriesEmpty = ref(true)

const emit = defineEmits(["selected"])

watchEffect(() => {
    console.log("Categories: ", selectedCategories.value);
    selectedCategoriesEmpty.value = selectedCategories.value.length === 0;
});

onMounted(async () => {
    var categoriesResponse = await CategoriesService.getApiV1CategoriesListCategories(1, 100, 0);
    
    if(categoriesResponse.categoriesList){
        categoriesResponse.categoriesList.forEach(category => {
        if(category.categoryName){
            categories.value.push(category.categoryName);
        }
    });
}
})

function selectCategories(){
    emit('selected', selectedCategories.value)
}
</script>





<template>
    <div class="flex flex-row">
        <MultiSelect v-model="selectedCategories" :options="categories ?? []" 
        placeholder="Select Widget Categories">
            <template>
                <span>{{ categories }}</span>
            </template>
            <template #footer>
                <Button class="w-full" label="Set Categories" type="submit" :disabled="selectedCategoriesEmpty" @click="selectCategories()"></Button>
            </template>
        </MultiSelect>

    </div>
</template>
<style>
  .p-multiselect-panel{
    z-index: 80000 !important;
  }
</style>