<template>
    <div class="flex flex-column m-2 gap-4">
      <ModelUpdater v-if="widgetConfiguration.widget?.type" :model="widgetConfiguration.model" :type="widgetConfiguration.widget?.type" @modelUpdated="updateModel" />
    </div>
    <div class="flex flex-row m-2 mt-5 justify-content-between">
      <Button label="Cancel" @click="onCancel()" />
      <Button label="Confirm" @click="dispatchUpdateWidgetConfigurationAsync()" />
    </div>
  </template>
  <script setup lang="ts">
  import {  ModelUpdater } from '@/earth/components';
  import Button from 'primevue/button';
  import { 
    WidgetType, 
    type Link, 
    type Embedded, 
    WidgetConfigurationsService, 
    type ListUsersWidgetConfigurationsResponse_WidgetConfiguration as WidgetConfiguration 
} from '@/apis/earth-api';
  import { ref } from 'vue';

  
  const props = defineProps<{
    widgetConfiguration: WidgetConfiguration
  }>();
  
  const emits = defineEmits(["onCancel", "onEdit"])
  //general data
  const widgetConfiguration = ref<WidgetConfiguration>(props.widgetConfiguration);
  
  //functions
  
  async function dispatchUpdateWidgetConfigurationAsync() {
    await WidgetConfigurationsService.putApiV1WidgetConfigurationsUpdate({
      widgetConfigurationId: widgetConfiguration.value.widgetConfigurationId,
      model: widgetConfiguration.value.model
    })
  
    emits("onEdit")
  }
  
  function updateModel(model: Link | Embedded) {
    console.log('Model updated', model)
    
    widgetConfiguration.value.model.title = model.title;
    widgetConfiguration.value.model.subTitle = model.subtitle;

    switch (widgetConfiguration.value.widget?.type) {
      case WidgetType.LINK: {
        if (('linkText' && 'linkUrl') in model) {
          console.log("Testlog123123")
          widgetConfiguration.value.model.linkText = model.linkText;
          widgetConfiguration.value.model.linkUrl = model.linkUrl
        }
        break;
      } case WidgetType.EMBEDDED: {
        if ('url' in model) {
          widgetConfiguration.value.model.url = model.url;
        }
        break;
      }
    }
  }
  
    function onCancel() {
      emits("onCancel")
    }
  </script>