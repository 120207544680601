<template>
  <div>

    <ConPopupDialog :visible="showImportDialog" title="Import Users" @onHide="showImportDialog = false">
      <div>
        <label class=""></label>
        <span class="p-input-icon-left w-full">
          <i v-if="isImporting" class="pi pi-spin pi-spinner" />
          <i v-else class="pi pi-search" />
          <InputText class="w-full" v-model="userQuery"
            placeholder="Input a user's email or name to import them into the Ceres User Table" />
        </span>
        <ConDataTable ref="importedTable" title="Imported users" :onReload="fetchImported" :columns="columns"
          :paginator="false" />
      </div>
    </ConPopupDialog>

    <ConPopupDialog :visible="showEditUserDialog" title="Edit User" @onHide="showEditUserDialog = false">
      <ManageCompanies @onDialogClose="closeDialog">

      </ManageCompanies>
    </ConPopupDialog>


    <Toolbar>
      <template #start>
        <Button class="mr-3" label="Import Users" @click="() => showImportDialog = true" />
        <Button label="Manage Users" @click="goToKeycloak" />
      </template>
    </Toolbar>
    <ConDataTable ref="table" title="Users" :onReload="fetchData" :columns="columns" :rows="25" filterDisplay="menu"
      :createActionMenu="createActionMenu" />
  </div>
</template>

<script setup lang="ts">
import { ConDataTable, ConPopupDialog } from '@congatec/primevue-components-lib';
import { UserService, CompanyService } from '@/apis/ceres-api'
import { ref, watch, type Ref } from 'vue';
import { delay, useDelayTimer } from '@congatec/primevue-components-lib';
import InputText from 'primevue/inputtext';
import Toolbar from 'primevue/toolbar';
import Button from 'primevue/button';
import ManageCompanies from '@/ceres/components/Companies/ManageCompanies.vue'
import { useCeresUserStore } from '../store/CeresUserStore'
const keycloakUrl = import.meta.env.VITE_KEYCLOAK_URL
const userQueryTimer = useDelayTimer();
const showImportDialog = ref(false);


const isImporting = ref(false);
const userQuery = ref("");
const table = ref<null | typeof ConDataTable>(null);
const importedTable = ref<null | typeof ConDataTable>(null);
const usersImported: Ref<any> = ref([]);
const showEditUserDialog = ref(false);
const companies = ref<any>([]);
const ceresUserStore = useCeresUserStore();
const columns = [
  {
    field: "firstName",
    header: "First Name",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "lastName",
    header: "Last Name",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "email",
    header: "E-Mail",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "actionMenu",
  }
]

const fetchData = async (filters: string, sorts: string, page: number, rows: number) => {
  let res = await UserService.getApiV1User(filters, sorts, page, rows);
  companies.value = (await CompanyService.getApiV1CompanyList())?.companies || [];
  return {
    totalCount: res.totalCount || 0,
    data: res.users || []
  };
}

watch(userQuery, (query: string) => {
  delay(userQueryTimer, async () => {
    isImporting.value = true;
    let res = await UserService.getApiV1UserImport(query);
    usersImported.value = res.users;
    console.log("Found", res);
    table.value?.forceReload();
    importedTable.value?.forceReload();
    isImporting.value = false;
  });
});

const fetchImported = async (): Promise<any> => {
  let data = {
    totalItems: usersImported.value.length,
    data: usersImported.value
  };
  return data;
}

const goToKeycloak = () => {
  window.open(keycloakUrl,'_blank');
}



const createActionMenu = (row: any): any => {
  return [
    {
      label: 'Edit',
      icon: 'pi pi-pencil',
      command: () => {
        ceresUserStore.set(row);
        showEditUserDialog.value = true;
      }
    },
  ];
}

function closeDialog(){
  showEditUserDialog.value = false;
  table.value?.forceReload();
}
</script>