<script setup lang="ts">

import Dropdown from 'primevue/dropdown';
import { onMounted, ref, watch } from 'vue';
import { useToast } from 'primevue/usetoast'
import { CategoriesService, type ListCategoriesResponse_Categories, type EditCategoriesRequest, type DeleteCategoriesRequest } from '@/apis/earth-api';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { useConfirm } from 'primevue/useconfirm';


const toast = useToast();
const confirm = useConfirm();
const dialogVisible = ref(false);
const categories = ref<ListCategoriesResponse_Categories[]>([]); 
const selectedCategory = ref<ListCategoriesResponse_Categories>();
const newCategoryName= ref();
const newCategoryDescription=ref();

onMounted(async () => {
    await loadCategories()
})


const props = defineProps<{
    visible: boolean,
    onHide: (edited: boolean) => any
}>();

watch(selectedCategory, (nv) =>{
    if(nv){
        newCategoryName.value = nv.categoryName;
        newCategoryDescription.value = nv.categoryDescription;
    }else{
        newCategoryName.value = ''
        newCategoryDescription.value = ''
    }
})

watch(() => props.visible, (nv) => {
console.log("VISIBLE: ", nv);
if (nv != undefined) {
    dialogVisible.value = nv;
}
}, { deep: true, immediate: true });

watch(() => dialogVisible.value, (nv) => {
    if(nv != null && !nv){
        props.onHide(true);
    }
})

watch(() => dialogVisible.value, (nv)=>{
    if(nv){
        loadCategories();
    }else{
        selectedCategory.value = undefined;
        newCategoryName.value = '';
        newCategoryDescription.value = '';
    }
});

async function loadCategories(){
    categories.value = []
    var categoriesResponse = await CategoriesService.getApiV1CategoriesListCategories(1, 100, 0);
    
    if(categoriesResponse.categoriesList){
        categoriesResponse.categoriesList.forEach(category => {
        if(category){
            categories.value.push(category);
        }
        });
    }
}
async function DeleteCategoriesAsync(r: DeleteCategoriesRequest){
    await CategoriesService.deleteApiV1CategoriesDeleteCategories(r);
}
async function EditCategoriesAsync(r: EditCategoriesRequest){
    await CategoriesService.putApiV1CategoriesEditCategories(r);
}

const confirmDelete = (categoryId : any) => {
    confirm.require({
        message: 'Do you want to delete the Category "' + selectedCategory.value?.categoryName + '"?' + "\n This will also remvoe all existing Widget Categories!" ,
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-info',
        accept: async () => {
            try {
                DeleteCategoriesAsync({
                    categoryId: categoryId
                })
                toast.add({ severity: 'success', summary: 'Delete', detail: 'Category Deleted', life: 3000 });
                dialogVisible.value = false
            }
            catch (ex: any) {
                toast.add({ severity: 'error', summary: 'Error', detail: 'An error occured while deleting the pool: ' + ex.message, life: 3000 });
            }
        },
        reject: async () => {
            // toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        }
    });
}

</script>

<template>
    <Dialog 
        v-if="dialogVisible"
        v-model:visible="dialogVisible" 
        modal 
        :draggable="false" 
        header="Create Category" 
        position="top" 
        style="{
        max-width: 50%;
        min-width: 30%;
        max-height: 90%}"
        >
        <template #default>
            <div class="flex flex-column">
                <label for="categorySelector">Sleect a Category to Edit or Delete</label>
                <Dropdown style="margin-bottom:5%;" id="categorySelector" v-model="selectedCategory" :options="categories" :option-label="'categoryName'" placeholder="Select a category">
                </Dropdown>
            </div>
            <div class="card flex justify-content-left">
                <div class="flex flex-column gap-5">
                    <div class="flex flex-column gap-1">
                        <label for="newName">Category Name</label>
                        <InputText id="newName" type="text" v-model="newCategoryName"></InputText>
                        <small id="name-help">Edit the Name for the Category</small>
                    </div>
                    <div class="flex flex-column gap-1">
                        <label for="newDescription">Category Description</label>
                        <InputText id="newDescription" type="text" v-model="newCategoryDescription"></InputText>
                        <small id="description-help">Edit the Description for the Category</small>
                    </div>
                    
                </div>
            </div>
        </template>
        <template #footer>
            <div class="flex flex-1 justify-content-between">
                <div>
                    <Button class="p-button-primary" label="Delete" :onClick="() =>{
                        confirmDelete(selectedCategory?.categoryId);
                        //dialogVisible = false;
                    }"/>
                </div>
                <div >
                    <Button class="p-button-text" label="Cancel" :onClick="() => {
                        dialogVisible = false;
                    }" />
                    <Button class="p-button-primary" label="Save" :onClick="async () => {
                        EditCategoriesAsync({
                            categoryId: selectedCategory?.categoryId,
                            categoryName: newCategoryName,
                            categoryDescription: newCategoryDescription
                        })
                        selectedCategory = undefined;
                        dialogVisible = false;
                    }" />
                </div >                    
            </div>
        </template>
    </Dialog>
</template>