import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";
import { type Ref } from "vue";
import type { ListUserResponse_User } from "@/apis/ceres-api";

const STORAGE_CERESUSER_KEY = "Selected-CeresUser";
export const useCeresUserStore = defineStore("useCeresUserStore", () => {
  const selectedUser : Ref<ListUserResponse_User | undefined> = useStorage(STORAGE_CERESUSER_KEY, undefined);
  
  const set = (newToken: ListUserResponse_User) => {
    selectedUser.value = newToken;
  };

  const clear = () => {
    selectedUser.value = undefined;
  }

  return { selectedUser , set, clear };
});
