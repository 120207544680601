/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateWidgetConfigurationsRequest } from '../models/CreateWidgetConfigurationsRequest';
import type { CreateWidgetConfigurationsResponse } from '../models/CreateWidgetConfigurationsResponse';
import type { DeleteWidgetConfigurationsRequest } from '../models/DeleteWidgetConfigurationsRequest';
import type { DeleteWidgetConfigurationsResponse } from '../models/DeleteWidgetConfigurationsResponse';
import type { UpdateWidgetConfigurationsRequest } from '../models/UpdateWidgetConfigurationsRequest';
import type { UpdateWidgetConfigurationsResponse } from '../models/UpdateWidgetConfigurationsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WidgetConfigurationsService {

    /**
     * @param requestBody 
     * @returns CreateWidgetConfigurationsResponse Success
     * @throws ApiError
     */
    public static postApiV1WidgetConfigurationsCreate(
requestBody?: CreateWidgetConfigurationsRequest,
): CancelablePromise<CreateWidgetConfigurationsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/WidgetConfigurations/Create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns UpdateWidgetConfigurationsResponse Success
     * @throws ApiError
     */
    public static putApiV1WidgetConfigurationsUpdate(
requestBody?: UpdateWidgetConfigurationsRequest,
): CancelablePromise<UpdateWidgetConfigurationsResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/WidgetConfigurations/Update',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns DeleteWidgetConfigurationsResponse Success
     * @throws ApiError
     */
    public static deleteApiV1WidgetConfigurationsDelete(
requestBody?: DeleteWidgetConfigurationsRequest,
): CancelablePromise<DeleteWidgetConfigurationsResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/WidgetConfigurations/Delete',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
