/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DWHDatabaseBehaviorResponse } from '../models/DWHDatabaseBehaviorResponse';
import type { DwhDatabaseCheckOrderFields } from '../models/DwhDatabaseCheckOrderFields';
import type { DwhDatabaseCheckOrderFieldsOrderingFieldsBase } from '../models/DwhDatabaseCheckOrderFieldsOrderingFieldsBase';
import type { DwhDatabaseCreateRequest } from '../models/DwhDatabaseCreateRequest';
import type { DwhDatabaseCreateResponse } from '../models/DwhDatabaseCreateResponse';
import type { DwhDatabaseDeleteResponse } from '../models/DwhDatabaseDeleteResponse';
import type { DwhDatabaseDetailsResponse } from '../models/DwhDatabaseDetailsResponse';
import type { DwhDatabaseListResponse } from '../models/DwhDatabaseListResponse';
import type { DwhDatabasePerformanceFolderListRequest } from '../models/DwhDatabasePerformanceFolderListRequest';
import type { DwhDatabasePerformanceFolderListResponse } from '../models/DwhDatabasePerformanceFolderListResponse';
import type { DwhDatabasePerformancePackageListResponse } from '../models/DwhDatabasePerformancePackageListResponse';
import type { DwhDatabasePerformanceResponse } from '../models/DwhDatabasePerformanceResponse';
import type { DwhDatabasesSwitchResponse } from '../models/DwhDatabasesSwitchResponse';
import type { DwhDatabaseTablesResponse } from '../models/DwhDatabaseTablesResponse';
import type { DwhDatabaseUpdateRequest } from '../models/DwhDatabaseUpdateRequest';
import type { DwhDatabaseUpdateResponse } from '../models/DwhDatabaseUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DwhDatabaseService {

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @param pageNumber 
     * @param pageSize 
     * @param skip 
     * @param orderingFields 
     * @param availableOrderingFields 
     * @returns DwhDatabaseListResponse Success
     * @throws ApiError
     */
    public static getApiV1DwhDatabase(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
pageNumber?: number,
pageSize?: number,
skip?: number,
orderingFields?: Array<DwhDatabaseCheckOrderFieldsOrderingFieldsBase>,
availableOrderingFields?: DwhDatabaseCheckOrderFields,
): CancelablePromise<DwhDatabaseListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/DwhDatabase',
            query: {
                'sieveModel.Filters': sieveModelFilters,
                'sieveModel.Sorts': sieveModelSorts,
                'sieveModel.Page': sieveModelPage,
                'sieveModel.PageSize': sieveModelPageSize,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
                'Skip': skip,
                'OrderingFields': orderingFields,
                'AvailableOrderingFields': availableOrderingFields,
            },
        });
    }

    /**
     * @param id 
     * @returns DwhDatabaseDeleteResponse Success
     * @throws ApiError
     */
    public static deleteApiV1DwhDatabaseDwhDatabasesDelete(
id?: number,
): CancelablePromise<DwhDatabaseDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/DwhDatabase/DwhDatabasesDelete',
            query: {
                'Id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns DwhDatabaseDetailsResponse Success
     * @throws ApiError
     */
    public static getApiV1DwhDatabaseDwhDatabaseDetails(
id?: number,
): CancelablePromise<DwhDatabaseDetailsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/DwhDatabase/DwhDatabaseDetails',
            query: {
                'Id': id,
            },
        });
    }

    /**
     * @param databaseId 
     * @returns DwhDatabaseTablesResponse Success
     * @throws ApiError
     */
    public static getApiV1DwhDatabaseDwhDatabaseTables(
databaseId?: number,
): CancelablePromise<DwhDatabaseTablesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/DwhDatabase/DwhDatabaseTables',
            query: {
                'DatabaseId': databaseId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns DwhDatabaseUpdateResponse Success
     * @throws ApiError
     */
    public static putApiV1DwhDatabaseDwhDatabseUpdate(
requestBody?: DwhDatabaseUpdateRequest,
): CancelablePromise<DwhDatabaseUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/DwhDatabase/DwhDatabseUpdate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns DwhDatabaseCreateResponse Success
     * @throws ApiError
     */
    public static postApiV1DwhDatabaseDwhDatabaseCreate(
requestBody?: DwhDatabaseCreateRequest,
): CancelablePromise<DwhDatabaseCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/DwhDatabase/DwhDatabaseCreate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param dwhDatabaseId 
     * @returns DwhDatabasesSwitchResponse Success
     * @throws ApiError
     */
    public static optionsApiV1DwhDatabaseDwhDatabaseSwitch(
dwhDatabaseId?: number,
): CancelablePromise<DwhDatabasesSwitchResponse> {
        return __request(OpenAPI, {
            method: 'OPTIONS',
            url: '/api/v1/DwhDatabase/DwhDatabaseSwitch',
            query: {
                'DwhDatabaseId': dwhDatabaseId,
            },
        });
    }

    /**
     * @param table 
     * @param dwhDatabaseId 
     * @param fromDate 
     * @param toDate 
     * @returns DWHDatabaseBehaviorResponse Success
     * @throws ApiError
     */
    public static getApiV1DwhDatabaseDwhDatabaseBehavior(
table?: string,
dwhDatabaseId?: number,
fromDate?: string,
toDate?: string,
): CancelablePromise<DWHDatabaseBehaviorResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/DwhDatabase/DWHDatabaseBehavior',
            query: {
                'Table': table,
                'DwhDatabaseID': dwhDatabaseId,
                'FromDate': fromDate,
                'ToDate': toDate,
            },
        });
    }

    /**
     * @param selectedDwhId 
     * @param etlPackage 
     * @param folder 
     * @param fromDate 
     * @param toDate 
     * @returns DwhDatabasePerformanceResponse Success
     * @throws ApiError
     */
    public static getApiV1DwhDatabaseDwhDatabasePerformance(
selectedDwhId?: number,
etlPackage?: string,
folder?: string,
fromDate?: string,
toDate?: string,
): CancelablePromise<DwhDatabasePerformanceResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/DwhDatabase/DWHDatabasePerformance',
            query: {
                'selectedDwhId': selectedDwhId,
                'EtlPackage': etlPackage,
                'Folder': folder,
                'FromDate': fromDate,
                'ToDate': toDate,
            },
        });
    }

    /**
     * @param request 
     * @returns DwhDatabasePerformanceFolderListResponse Success
     * @throws ApiError
     */
    public static getApiV1DwhDatabaseDwhDatabasePerformanceFolderList(
request?: DwhDatabasePerformanceFolderListRequest,
): CancelablePromise<DwhDatabasePerformanceFolderListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/DwhDatabase/DWHDatabasePerformanceFolderList',
            query: {
                'request': request,
            },
        });
    }

    /**
     * @param foldername 
     * @returns DwhDatabasePerformancePackageListResponse Success
     * @throws ApiError
     */
    public static getApiV1DwhDatabaseDwhDatabasePerformancePackageList(
foldername?: string,
): CancelablePromise<DwhDatabasePerformancePackageListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/DwhDatabase/DwhDatabasePerformancePackageList',
            query: {
                'Foldername': foldername,
            },
        });
    }

}
