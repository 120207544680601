<template>
    <div class="w-full h-full flex flex-column align-items-center mt-3 gap-6">
        <!-- <PickList v-model="companies" listStyle="height:342px" class="flex w-6" dataKey="id">
                <template #sourceheader>Available</template>
                <template #targetheader> Selected </template>
                <template #item="slotProps">
                    <div class="flex flex-wrap p-2 align-items-center gap-3">
                        <img class="w-4rem shadow-2 flex-shrink-0 border-round"
                            :alt="slotProps.item.name" />
                        <div class="flex-1 flex flex-column gap-2">
                            <span class="font-bold">{{ slotProps.item.name }}</span>
                            <div class="flex align-items-center gap-2">
                                <i class="pi pi-tag text-sm"></i>
                                <span>{{ slotProps.item.address }}</span>
                            </div>
                        </div>
                    </div>
                </template>
            </PickList> -->
        <div class="flex flex-column w-6">
            <ConDetailsItem icon="pi pi-user" title="User"
                :content="`${ceresUserStore.selectedUser?.firstName} ${ceresUserStore.selectedUser?.lastName}`" />
            <ConDetailsItem icon="pi pi-envelope" title="E-Mail" :content="ceresUserStore.selectedUser?.email" />

            <div class="mt-5">
                <ConDetailsItem divider icon="pi pi-building" title="Companies" />
                <AutoComplete v-model="companyQuery" :suggestions="companiesFound" @item-select="addCompany" id="newCompany"
                    class="w-full mb-3" placeholder="Enter company name" optionLabel="identifier" />
                <Listbox :options="companies" multiple optionLabel="name" class="w-full">
                    <template #option="slotProps">
                        <div class="flex flex-row justify-content-between align-items-center">
                            <div>{{ slotProps.option.name }}</div>
                            <Button class="mr-5 " text rounded icon="pi pi-trash" @click="removeCompany(slotProps.option.id)"></Button>
                        </div>
                    </template>
                </Listbox>
            </div>
            <div class="mt-3">
                <Button class="mr-3" label="Save" @click="saveEditUser" />
                <Button label="Cancel" @click="closeDialog" />
            </div>
        </div>

    </div>
</template>
<script setup lang="ts">
import Listbox from 'primevue/listbox';
import { onMounted, ref, watch, type Ref } from 'vue';
import { type ListCompaniesResponse_Company, CompanyService, UserService } from '@/apis/ceres-api';
import { useCeresUserStore } from '../store/CeresUserStore'
import AutoComplete from 'primevue/autocomplete';
import { delay, useDelayTimer, ConDetailsItem } from '@congatec/primevue-components-lib';
import Button from 'primevue/button';

const companyQuery = ref("");
const companyQueryTimer = useDelayTimer();
const companiesFound = ref<any[]>([]);
const ceresUserStore = useCeresUserStore();
const companies: Ref<ListCompaniesResponse_Company[]> = ref([])
const emits = defineEmits(['onDialogClose'])
onMounted(() => {
    if (ceresUserStore.selectedUser?.companies) {
        companies.value = ceresUserStore.selectedUser?.companies
    }
})

const addCompany = (item: any) => {
    console.log("Item pushed: ", item)
    companies.value.push(item.value);
    console.log("Companies:", companies)
}
const removeCompany = (id: any) =>{
  let index = companies.value.findIndex(x => x.id === id) 
  companies.value.splice(index,1);
}
const saveEditUser = async () => {
  let companiesIds = companies.value.map((x: any) => x.id);
  await UserService.putApiV1User({ id: ceresUserStore.selectedUser?.id, companies: companiesIds });
  closeDialog();
}

function closeDialog(){
    emits("onDialogClose");
}
watch(companyQuery, async () => {
    delay(companyQueryTimer, async () => {
        if (!companyQuery.value) {
            companiesFound.value = [];
            return;
        }
        companiesFound.value = (await CompanyService.getApiV1CompanyList(`companyName@=${companyQuery.value}`, "", 1, 10)).companies || [];

        for (let company of companiesFound.value) {
            if (company.name) {
                company.identifier = company.name;
            } else {
                company.identifier = `${company.id}`
            }
        }
    });

});
// const companies:Ref<ListCompaniesResponse_Company[][]> = ref([[],[]] )
// watch(companies,()=>{
//     console.log("Products:", companies.value[1])
// },{deep:true})
</script>