<script setup lang="ts">
import { WidgetConfigurationsService } from "@/apis/earth-api";
import Button from "primevue/button";


const props = defineProps<{
    widgetId: any
}>();

console.log("WIDGET ID: ", props.widgetId);

async function createWidgetConfigurationAsync(widgetId: any){
  await WidgetConfigurationsService.postApiV1WidgetConfigurationsCreate({
    widgetId
  });
}

</script>
<template>
  <Button
    v-tooltip="'Add to Dashboard'"
    label="Add"
    class="p-button-sm p-button-rounded p-button-text"
    icon="pi pi-th-large"
    @click="(e) => createWidgetConfigurationAsync(widgetId)"
  ></Button>
</template>
<style lang="scss">
</style>