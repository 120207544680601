<script setup lang="ts">
import Card from "primevue/card";
import { useSlots, toRefs, type PropType, watch, ref } from "vue";
import { WidgetType, type Link } from "@/apis/earth-api";

import { BaseWidget } from "@/earth/components";

const props = defineProps<{
  model: Link
}>();

const slots = useSlots();

// const { createWidget } = useWidgetSmith();
// const Foo = createWidget<WidgetType,BaseWidgetData>("BaseWidget", props);


</script>
<template>
  <BaseWidget v-bind="props" :type="WidgetType.LINK">
    <template #default>
      <div class="flex flex-column align-items-center">
        <a target="_blank" class="flex align-items-center justify-content-center gap-2"
          v-if="model.linkUrl && model.linkUrl != null" :href="model.linkUrl">{{ model.linkText }}
          <i class="pi pi-external-link" /></a>

      </div>
    </template>
    <!-- To pass down all slots to Widget -->
    <template v-for="(_, name) in slots" v-slot:[name]="scopedSlotData">
      <slot :name="name" v-bind="scopedSlotData"></slot>
    </template>
  </BaseWidget>
</template>
