import { DashboardView, MarketplaceView } from "@/earth/components";
import { AdminView } from "@/earth/components";
import { to } from "@congatec/primevue-components-lib";



export const earthRoutes = [
  {
    path: "/earth/dashboard",
    name: "dashboard",
    component: DashboardView,
    meta:{
      breadcrumb:[{ title: 'Earth'},{ label: 'Dashboard'}]
    }
  },
  {
    path: "/earth/marketplace",
    name: "marketplace",
    component: MarketplaceView,
    meta:{
      breadcrumb:[{ label: 'Earth', command:to('/earth/dashboard')},{ label: 'Marketplace'}]
    }
  },
  {
    path: "/earth/admin",
    name: "admin",
    component: AdminView,
    meta:{
      breadcrumb:[{ label: 'Earth', command:to('/earth/dashboard')},{ label: 'Admin View'}]
    }
  },


];

export default earthRoutes;
