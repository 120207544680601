/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum StandardSize {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}
