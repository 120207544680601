<template>
    <Card id="generalData">
      <template #title>
        <!-- <h3 style="color: var(--primary-color)"></h3> -->
        General Widget Data
      </template>
      <template #content>
        <div class="flex flex-column gap-3">
          <div class="flex flex-row gap-5">
            <div class="flex flex-column gap-1 w-full">
              <label for="title">Widget Title</label>
              <InputText id="title" v-model="widgetTitle" :value="widgetTitle" />
            </div>
            <div class="flex flex-column gap-1 w-full">
              <label for="subTitle">Widget SubTitle</label>
              <InputText id="subTitle" v-model="widgetSubTitle" :value="widgetSubTitle" />
            </div>
          </div>
          <div class="flex flex-row gap-5" v-if="props.isWidget">
            <div class="flex flex-column gap-1 w-full">
              <label for="details">Widget Details</label>
              <Textarea id="details" v-model="widgetDetails" :value="widgetDetails" autoResize rows="8" cols="30" />
            </div>
          </div>
        </div>
      </template>
    </Card>
    <Card class="flex flex-column" v-show="props.type" v-if="props.type && props.type !== WidgetType.JIRA_TIME_BOOKING">
      <template #title>
        Specific Widget Data
      </template>
      <template #content>
        <!-- Content for Link Widget-->
        <div class="flex flex-row gap-5" v-if="props.type === WidgetType.LINK">
          <div class="flex flex-column w-full">
            <label for="linkUrl">Link URL</label>
            <InputText id="linkUrl" v-model="linkUrl" :value="linkUrl" />
          </div>
          <div class="flex flex-column w-full">
            <label for="linkText">Link Text</label>
            <InputText id="linkText" v-model="linkText" :value="linkText"/>
          </div>
        </div>
        <!-- Content for Embedded Widget-->
        <div class="flex flex-column" v-if="props.type === WidgetType.EMBEDDED">
          <label for="embeddedUrl">Url</label>
          <InputText id="embeddedUrl" v-model="url" :value="url"/>
        </div> 
      </template>
    </Card>
</template>
<script setup lang="ts">
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import {  onMounted, ref, watch } from 'vue';
import { type Link, type Embedded, WidgetType as widgetType, WidgetType } from '@/apis/earth-api';

const props = defineProps<{
  type?: widgetType,
  model?: Link | Embedded,
  isWidget?: boolean
}>();
const emits = defineEmits(['modelUpdated'])

//general Data:
const widgetTitle = ref<string>('');
const widgetSubTitle = ref<string>('');
const widgetDetails = ref<string>('');

//link widget Data:

const linkUrl = ref<string>('')
const linkText = ref<string>('');
const widgetModel = ref<Link & Embedded>({});
//embedded widget Data:
const url = ref<string>('');

onMounted(()=>{
  widgetTitle.value = props.model?.title || '';
  widgetSubTitle.value = props.model?.subtitle || '';
  widgetDetails.value = props.model?.details || '';
  switch(props.type){
  case WidgetType.LINK:{
    if(props.model && 'linkUrl' in props.model && 'linkText' in props.model){
      linkUrl.value = props.model.linkUrl || '';
      linkText.value = props.model.linkText || '';
      widgetModel.value = props.model;
    }
    break;
  }case WidgetType.EMBEDDED:{
    if(props.model && 'url' in props.model){
      widgetModel.value = props.model;
      url.value = props.model.url || '';
    }
    break;
  }
}
})

watch(widgetTitle,(nv)=>{
  widgetModel.value.title = nv;
})

watch(widgetSubTitle,(nv)=>{
  widgetModel.value.subtitle = nv;
})

watch(widgetDetails,(nv)=>{
  widgetModel.value.details = nv;
},{deep:true})

watch(linkUrl,(nv)=>{
  widgetModel.value.linkUrl = nv;
  
})

watch(linkText,(nv)=>{
    widgetModel.value.linkText = nv;
  
})

watch(url,(nv)=>{
  widgetModel.value.url = nv;
})

watch(widgetModel, (nv)=>{
  console.log('LinkModelUpdate',nv);
  emits('modelUpdated', nv)
},{deep:true})

watch(url, (nv)=>{
  if('url' in widgetModel.value){
    widgetModel.value.url = nv
  }
})

</script>