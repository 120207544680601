<template>
  <Dialog v-if="dialogVisible" v-model:visible="dialogVisible" modal :draggable="false" :header="headerText"
    @update:visible="closeDialog" position="top" class="w-7">
    <template #default>
      <div class="flex flex-column  gap-5 w-full">
        <div class="flex flex-row gap-5 w-full">
          <div class="flex flex-row w-full gap-2">
            <div class="flex flex-column w-9">
              <label for="title">Title <span>*</span></label>
              <InputText id="title" v-model="cardState.title" :value="cardState.title" placeholder="Enter the Title"
                class="w-full" />
            </div>

            <div class="flex flex-column w-3 ">
              <label for="flag">Card Type <span>*</span></label>

              <Dropdown id="flag" v-model="cardState.flag" :options="cardFlags" optionLabel="label" optionValue="value"
                placeholder="Card Type" checkmark class="w-full border-primary" />
            </div>

          </div>
          <div class="w-full">
            <label for="description">Description <span>*</span></label>
            <InputText id="description" v-model="cardState.description" :value="cardState.description"
              placeholder="Enter Description" class="w-full" />

          </div>
        </div>
        <div class="flex flex-row gap-5 w-full">
          <div class="w-full">
            <label for="link">Link <span>*</span> & Option where link is opened</label>
            <div class="flex flex-row w-full gap-2">
              <AutoComplete id="link" v-if="!cardState.externalLink && cardState.flag == CardFlag.APP_CARD"
                v-model="cardState.route" :value="cardState.route" placeholder="Enter the Link" class="w-9"
                :class="{ 'w-full': cardState.flag == CardFlag.APP_CARD }"></AutoComplete>
              <InputText id="link" v-else v-model="cardState.route" :value="cardState.route"
                placeholder="Enter the Link" class="w-full" :class="{ 'w-9': cardState.flag == CardFlag.APP_CARD }" />
              <ToggleButton id="linkOption" v-if="cardState.flag == CardFlag.APP_CARD"
                class="w-3 surface-card border-primary" v-model="cardState.externalLink" onLabel="New Tab"
                offLabel="Internal" v-tooltip.bottom="{
                  value: 'Select if link should be opened internal or in new tab',
                  pt: {
                    arrow: {
                      style: {
                        borderBottomColor: 'var(--primary-color)',
                      }
                    },
                    text: 'bg-primary font-medium m-0'
                  }
                }" />

            </div>

          </div>
          <div class="w-full">
            <label>Tags </label>
            <div class="flex flex-column w-full gap-2">
              <div v-for="(x, index) in cardState.tags" :key="index" class="flex flex-column gap-2">
                <div class="w-full flex flex-column gap-5">

                  <InputGroup :id="'inputGroup' + index">
                    <InputText :id="'firstName' + index" v-model="cardState.tags![index].name" placeholder="Tag Name"
                      :value="cardState.tags![index].name" />
                    <div class="flex flex-row gap-1">
                      <Button :id="'btnRemove' + index" icon="pi pi-user-minus" @click="removeIndex(index)"
                        class="userbutton" text :disabled="cardState.tags!.length <= 1" />
                      <Button icon="pi pi-user-plus" @click="addIndex()" class="userbutton" text />
                    </div>
                  </InputGroup>
                </div>
              </div>
            </div>


          </div>

        </div>
        <div class="flex flex-row w-full">
          <div class="w-full">
            <FileUpload name="demo[]" ref="imageUpload" :showUploadButton="false" :show-cancel-button="false"
              @select="onImageSelect" :multiple="false" accept="image/*" :maxFileSize="1000000">
              <template #empty>
                <p>Drag and drop files here to upload. <span>*</span></p>
              </template>
            </FileUpload>
          </div>
        </div>
        <div class="flex flex-column w-full">
          <Fieldset legend="Additional Options" :toggleable="true" :collapsed="true">
            <div class="flex flex-column gap-4">
              <div class="flex flex-row gap-2">
                <InputSwitch v-model="cardState.sidebarItem" :value="cardState.sidebarItem"
                  :disabled="cardState.flag != CardFlag.REPORT_CARD" />
                <span class="text-color">Sidebar Item</span>
              </div>
              <div class="flex flex-row gap-2">
                <InputSwitch v-model="cardState.quicknavItem" :value="cardState.quicknavItem" />
                <span class="text-color">Quick navigation item</span>
              </div>
              <div class="flex flex-row gap-2">
                <div class="flex flex-column gap-2 w-6">
                  <span class="text-color">User Role</span>
                  <InputText v-model="cardState.userRole" :value="cardState.userRole" placeholder="Enter the User Role"
                    class="w-full" />
                </div>
                <div class="flex flex-column gap-2 w-6">
                  <span class="text-color">Client ID</span>
                  <InputText v-model="cardState.clientId" :value="cardState.clientId" placeholder="Enter the Client Id"
                    class="w-full" />
                </div>
              </div>
            </div>

          </Fieldset>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex flex-1 align-items-end justify-content-between">
        <Button class="p-button-text" label="Cancel" @click="() => {
          closeDialog();
        }" />
        <Button class="p-button-primary" label="Save" :onClick="async () => {
          if (props.edit) {
            let updateCardResponse = await CardsService.putApiV1Cards({
              id: props.card?.id,
              title: cardState.title,
              description: cardState.description,
              route: cardState.route,
              tags: cardState.tags,
              icon: cardState.icon,
              userRole: cardState.userRole,
              clientId: cardState.clientId,
              externalLink: cardState.externalLink,
              flag: cardState.flag,
              quicknavItem: cardState.quicknavItem,
              sidebarItem: cardState.sidebarItem
            })
            if (updateCardResponse.updatedCard) {
              let index = cardStore.cards.findIndex(x => x.id === props.card?.id)
              cardStore.cards[index] = updateCardResponse.updatedCard
            }
          } else {
            let createCardResponse = await CardsService.postApiV1Cards({
              title: cardState.title,
              description: cardState.description,
              route: cardState.route,
              tags: cardState.tags,
              icon: cardState.icon,
              userRole: cardState.userRole,
              clientId: cardState.clientId,
              externalLink: cardState.externalLink,
              flag: cardState.flag,
              quicknavItem: cardState.quicknavItem,
              sidebarItem: cardState.sidebarItem
            });
            if (createCardResponse.createdCard) {
              cardStore.add(createCardResponse.createdCard);
            }
          }
          closeDialog();
        }" />
      </div>
    </template>
  </Dialog>
</template>
<script setup lang="ts">
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import InputGroup from 'primevue/inputgroup';
import InputText from 'primevue/inputtext';
import { ref, watch } from 'vue';
import FileUpload from 'primevue/fileupload';
import { useToast } from 'primevue/usetoast';
import { CardsService, type CardsCreateRequest as CardType, type CardsListResponse_Card as CardTypeEdit, CardFlag } from '@/apis/jupiter-api';
import Dropdown from 'primevue/dropdown';
import ToggleButton from 'primevue/togglebutton';
import { useCardStore } from '@/stores/cardStore';
import AutoComplete from 'primevue/autocomplete';
import Fieldset from 'primevue/fieldset';
import InputSwitch from 'primevue/inputswitch';
// Dataelements (constants / variables) area
const cardStore = useCardStore();
const cardState = ref<CardType>({
  icon: '',
  title: '',
  description: '',
  route: '',
  userRole: '',
  clientId: '',
  flag: CardFlag.APP_CARD,
  externalLink: true,
  quicknavItem: false,
  sidebarItem: false,
  tags: [{ name: '' }]
});

const cardFlags = ref([
  { label: "App Card", value: CardFlag.APP_CARD },
  { label: "Report Card", value: CardFlag.REPORT_CARD }
]);
const dialogVisible = ref(false);
const tagCounter = ref(1);
const imgUrl = ref();
const imageUpload = ref();
const toast = useToast();
const headerText = ref('Create Card');
//props definition
const props = defineProps<{
  card?: CardTypeEdit,
  edit?: boolean,
  visible: boolean,
}>();

const emits = defineEmits(['onDialogClose']);

const closeDialog = () => {
  dialogVisible.value = false;
  cardState.value = {
    icon: '',
    title: '',
    description: '',
    route: '',
    userRole: '',
    clientId: '',
    flag: CardFlag.APP_CARD,
    externalLink: true,
    quicknavItem: false,
    sidebarItem: false,
    tags: [{ name: '' }]
  }
  emits('onDialogClose');
}

const addIndex = () => {
  tagCounter.value += 1;
  cardState.value.tags!.push({ name: "" });
}

const removeIndex = (index: number) => {
  if (cardState.value.tags!.length == 1)
    return;

  cardState.value.tags?.splice(index, 1);
  tagCounter.value -= 1;
}

const onImageSelect = (event: any) => {
  console.log("selectEvent: ", event)
  imgUrl.value = event.files[0].objectURL;
  // set seleced files as uploadedFiles so that the status badge shows as completed
  imageUpload.value.uploadedFiles = imageUpload.value.files;
  imageUpload.value.files = [];
  toast.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 });
  convertImageToBase64(event.files[0]);
};

const convertImageToBase64 = async (imageFile: any) => {
  let blob = await fetch(imageFile.objectURL).then((r) => r.blob()); //blob:url
  const reader = new FileReader();
  reader.readAsDataURL(blob);

  reader.onloadend = async function () {
    cardState.value.icon = reader.result as any as string;
  }
}
//watch area
watch(() => props.visible, async (nv: any) => {
  if (nv != undefined) {
    dialogVisible.value = nv;
  }
}, { deep: true, immediate: true });

watch(() => props.card, (nv) => {

  if (nv != undefined) {
    cardState.value.tags = [];
    if (nv?.tags) {
      nv.tags.forEach(tag => {
        cardState.value.tags?.push({ "name": tag })
      });
    }
    cardState.value.icon = nv.icon,
      cardState.value.title = nv.title,
      cardState.value.description = nv.description,
      cardState.value.userRole = nv.userRole,
      cardState.value.clientId = nv.clientId,
      cardState.value.route = nv.route,
      cardState.value.flag = nv.flag,
      cardState.value.externalLink = nv.externalLink,
      cardState.value.quicknavItem = nv.quicknavItem,
      cardState.value.sidebarItem = nv.sidebarItem
  }
}, { deep: true, immediate: true });


watch(() => cardState.value.flag, (nv) => {
  if (nv == CardFlag.REPORT_CARD) {
    cardState.value.externalLink = true
  }
})

watch(() => props.edit, (nv) => {
  console.log("Test", props.edit)
  if (nv) {
    headerText.value = 'Edit Card';
  }
}, { deep: true, immediate: true });
</script>

<style scoped>
span {
  color: var(--primary-color)
}
</style>