<script setup lang="ts">
import { ConCard } from "@congatec/primevue-components-lib";
import { onBeforeMount, ref, type Ref } from "vue";
import Button from "primevue/button";
import DwhDatabaseBehaviorView from "@/uranus/components/dwhdatabases/behavior/DwhDatabaseBehaviorView.vue";
import DwhDatabasePerformanceView from "../../dwhdatabases/performance/DwhDatabasePerformanceView.vue";
import OrderList from "primevue/orderlist";
import {
  DataQualityChecksService,
  type ListDataQualityCheckResponse,
} from "@/apis/uranus-api";
import { type DwhDatabaseListResponse_DwhDatabase } from "@/apis/uranus-api";

let data: Ref<Array<DwhDatabaseListResponse_DwhDatabase>> = ref([{}]);
let selectedItem: Ref<DwhDatabaseListResponse_DwhDatabase> = ref({});
let dialogRef: any = null;
const isLoading = ref(true);
const totalItems = ref(1);
let sieveFilterStatements = "";
let sieveSortStatements = "";

const tableOptions = ref<{
  page: number;
  sortBy: Array<string>;
  sortDesc: Array<boolean>;
  groupBy: Array<string>;
  groupDesc: Array<string>;
  multiSort: boolean;
  mustSort: boolean;
  itemsPerPage: number;
}>({
  page: 1,
  sortBy: ["id"],
  sortDesc: [true],
  groupBy: [],
  groupDesc: [],
  multiSort: true,
  mustSort: false,
  itemsPerPage: 25,
});

onBeforeMount(() => {
  fetchDataFromServiceAsync();
  tableOptions.value.page = 1;
});

async function fetchDataFromServiceAsync() {
  console.log("tableOptions page: ", tableOptions.value.page);
  const { page, itemsPerPage } = tableOptions.value;
  const request = {
    sieveModelFilters: "failedChecksFilter==1",
    sieveModelSorts: sieveSortStatements,
    sieveModelPage: page,
    sieveModelPageSize: itemsPerPage,
    pageNumber: page,
    pageSize: itemsPerPage,
  };
  if (request && tableOptions.value !== undefined) {
    isLoading.value = true;
    DataQualityChecksService.getApiV1DataQualityChecksList(
      request.sieveModelFilters,
      request.sieveModelSorts,
      request.sieveModelPage,
      request.sieveModelPageSize,
      request.pageNumber,
      request.pageSize,
      0,
    ).then((r) => {
      if (r?.dataQualityChecks && r.totalCount != undefined) {
        data.value = r.dataQualityChecks;
        totalItems.value = r.totalCount;
      }
      console.log(data.value);
      isLoading.value = false;
    });
  }
}
</script>

<template>
  <div class="flex flex-column gap-4 m-5 mt-7">
    <!-- landing-container -->
    <div class="flex justify-content-between w-full gap-4">
      <div class="flex justify-content-between w-6 gap-4">
        <ConCard class="landing-item" cardClass="w-full">
          <template #title> Data Quality Checks </template>
          <template #content>
            See the List of all Data Quality Checks
          </template>
          <template #footer>
            <router-link to="/uranus/dataqualitychecks">
              <Button
                class="landing-button"
                to="/uranus/dataqualitychecks"
                tag="button"
                >Open</Button
              >
            </router-link>
          </template>
        </ConCard>
        <ConCard class="landing-item" cardClass="w-full">
          <template #title> Executions </template>
          <template #content> See the List of all Executions </template>
          <template #footer>
            <router-link to="/uranus/executions">
              <Button class="landing-button" tag="button">Open</Button>
            </router-link>
          </template>
        </ConCard>
      </div>
      <div class="flex w-6 justify-content-between gap-4">
        <ConCard class="landing-item" cardClass="w-full">
          <template #title> DWH Databases </template>
          <template #content>
            See the List of all Data Warehouse Databases
          </template>
          <template #footer>
            <router-link to="/uranus/dwhDatabases">
              <Button
                class="landing-button"
                to="/uranus/dwhDatabases"
                tag="button"
                >Open</Button
              >
            </router-link>
          </template>
        </ConCard>
        <ConCard class="landing-item" cardClass="w-full">
          <template #title> Scheduled Executions </template>
          <template #content>
            See the List of all Scheduled Executions
          </template>
          <template #footer>
            <router-link to="/uranus/scheduledexecutions">
              <Button
                class="landing-button"
                to="/uranus/scheduledexecutions"
                tag="button"
                >Open</Button
              >
            </router-link>
          </template>
        </ConCard>
      </div>
    </div>
    <div class="flex justify-content-between gap-3 mb-3 w-full">
      <DwhDatabasePerformanceView class="w-5"></DwhDatabasePerformanceView>

      <DwhDatabaseBehaviorView class="w-5"></DwhDatabaseBehaviorView>

      <ConCard class="w-2">
        <template #title>Failed Data Quality Checks</template>
        <template #content>
          <OrderList v-model="data" listStyle="height:auto" dataKey="id">
            <template #item="element">
              <router-link :to="`/uranus/dataqualitychecks/${element.item.id}`">
                <div class="flex-1 flex flex-column gap-2">
                  <span class="font-bold">{{ element.item.table }}</span>
                  <div class="flex align-items-center gap-2">
                    <span>{{ element.item.description }}</span>
                  </div>
                </div>
              </router-link>
            </template>
          </OrderList>
        </template>
      </ConCard>
    </div>
  </div>
</template>

<style scoped>
.landing-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 1fr;
  margin: 1rem !important;
  grid-column-gap: 1rem;
}

.landing-item {
  height: 100%;
  width: 50%;
  text-align: center;
}

.landing-button {
  display: flex;
  width: 100%;
  justify-content: center;
}
</style>
