/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EditUserRequest } from '../models/EditUserRequest';
import type { EditUserResponse } from '../models/EditUserResponse';
import type { ImportKeycloakUserResponse } from '../models/ImportKeycloakUserResponse';
import type { ListUserResponse } from '../models/ListUserResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * @param query 
     * @returns ImportKeycloakUserResponse Success
     * @throws ApiError
     */
    public static getApiV1UserImport(
query?: string,
): CancelablePromise<ImportKeycloakUserResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/User/import',
            query: {
                'Query': query,
            },
        });
    }

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @returns ListUserResponse Success
     * @throws ApiError
     */
    public static getApiV1User(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
): CancelablePromise<ListUserResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/User',
            query: {
                'SieveModel.Filters': sieveModelFilters,
                'SieveModel.Sorts': sieveModelSorts,
                'SieveModel.Page': sieveModelPage,
                'SieveModel.PageSize': sieveModelPageSize,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns EditUserResponse Success
     * @throws ApiError
     */
    public static putApiV1User(
requestBody?: EditUserRequest,
): CancelablePromise<EditUserResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/User',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
