<script setup lang="ts">
import { useSlots} from "vue";
import { WidgetType, type Embedded as EmbeddedModel } from "@/apis/earth-api";
import { BaseWidget } from "@/earth/components";


const props = defineProps<{
  model: EmbeddedModel
}>();

const slots = useSlots();

// const { createWidget } = useWidgetSmith();
// const Foo = createWidget<WidgetType,BaseWidgetData>("BaseWidget", props);


</script>

<template>
  <BaseWidget v-bind="props" :type="WidgetType.EMBEDDED" class="con-embedded-widget h-full">
    <template #default>
      <div class="flex-1 h-full">
        <div class="flex flex-1 justify-content-center mt-2 h-full">
          <!-- @vue-ignore -->
          <iframe class="con-embedded-widget-iframe flex flex-1 mb-5 w-full h-full" :title="model.title" :src=" model.url"
            frameborder="0"></iframe>
        </div>
      </div>
    </template>
    <!-- To pass down all slots to Widget -->
    <template v-for="(_, name) in slots" v-slot:[name]="scopedSlotData">
      <slot :name="name" v-bind="scopedSlotData"></slot>
    </template>
  </BaseWidget>
</template>
<style lang="scss">
.con-embedded-widget>.p-panel>.p-toggleable-content {
  height: 100%;
  width: 100%;

  .p-panel-content {
    height: 100%;
    width: 100%;
  }


}
</style>
