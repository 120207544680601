<script setup lang="ts">
import { WidgetsDashboardGrid } from "@/earth/components";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel'
import { ref } from "vue";
import { TabsService } from '@/apis/earth-api/services/TabsService'
import { type ListTabsResponse_Tab } from "@/apis/earth-api";
import { onKeycloakToken } from "@congatec/authentication-lib";
import { req } from "@/shared/handleError";
import Button from "primevue/button";
import { useConfirm } from "primevue/useconfirm";
import { useTabsStore } from "@/earth/store/tabsStore";
import { useToast } from 'primevue/usetoast';
import ContextMenu from 'primevue/contextmenu';
import InputText from "primevue/inputtext";

const toast = useToast();
const store = useTabsStore();
const editTab = ref<number>(-1);
const nameBefore = ref<string | undefined | null>("");
const confirm = useConfirm();
const active = ref(0);

const tabMenuSelectTab = ref();
const tabMenuSelectIndex = ref();

const moveTabLeft = async () => {
  if (!store.tabs) {
    return;
  }
  let prevTab = store.tabs[(tabMenuSelectIndex.value || 0) - 1];

  if (prevTab && prevTab.order) {
    tabMenuSelectTab.value.order = prevTab.order - 1;
    await req(() => TabsService.putApiV1TabsUpdate(tabMenuSelectTab.value), toast);
    store.set(tabMenuSelectTab.value, tabMenuSelectIndex.value);
    store.load(store.tabs.sort((a, b) => (a.order || 0) - (b.order || 0)))
  }
}

const moveTabRight = async () => {
  if (!store.tabs) {
    return;
  }
  let nextTab = store.tabs[(tabMenuSelectIndex.value || 0) + 1];

  if (nextTab && nextTab.order) {
    tabMenuSelectTab.value.order = nextTab.order + 1;
    await req(() => TabsService.putApiV1TabsUpdate(tabMenuSelectTab.value), toast);
    store.set(tabMenuSelectTab.value, tabMenuSelectIndex.value);
    store.load(store.tabs.sort((a, b) => (a.order || 0) - (b.order || 0)))
  }
}


const tabMenu = ref();
const tabMenuItems = [{ label: 'Move left', command: moveTabLeft }, { label: 'Move right', command: moveTabRight }];


onKeycloakToken(async () => {
  store.load((await req(async () => {
    return await TabsService.getApiV1TabsList();
  }))?.tabs || []);
});

const onNewTab = async () => {
  (await req(async () => {
    let id = (await TabsService.postApiV1TabsCreate({})).id;

    store.newTab(id || 0);

    return id;
  }));
}

const onTabEdited = async (tab: ListTabsResponse_Tab, _index: number) => {
  editTab.value = -1;
  await req(async () => {
    await TabsService.putApiV1TabsUpdate(tab);
  })
}

const onTabRightClick = (event: any, tab: ListTabsResponse_Tab, index: number) => {
  tabMenuSelectTab.value = tab;
  tabMenuSelectIndex.value = index;
  tabMenu.value[0].show(event);
};

const onTabDelete = async (event: any, tab: ListTabsResponse_Tab, index: number) => {
  console.log(event.currentTarget);
  confirm.require({
    target: event.currentTarget,
    message: `Do you want to delete this tab? This will remove all widgets from the tab!`,
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-danger',
    accept: async () => {
      await req(async () => {
        await TabsService.deleteApiV1TabsDelete({ deleteTabId: tab.id, moveToTab: false, moveToTabId: null });
        store.remove(index);
      }, toast);
      active.value = 0;
    },
  });
}

</script> 
<template>
  <div class="m-0 mt-0 flex flex-column">
    <ConfirmPopup />
    <!-- TODO: 
         TabView currently does not support 
         sorting via drag and drop, however 
         this is a proposed feature for primevue 
         as of 2023-03-20.
         https://github.com/primefaces/primevue/issues/3775
    -->
    <TabView :activeIndex="active" :scrollable="true" :lazy="true" style="margin-top: 0 !important; margin: 0 !important">
      <TabPanel class="w-full h-full">
        <template #header>
          <i class="pi pi-home" />
          <p>Home</p>
        </template>
        <WidgetsDashboardGrid :tabId="null" />
      </TabPanel>

      <TabPanel v-for="(tab, index) in store.tabs" v-bind:key="tab.id">
        <template #header>
          <div @contextmenu="onTabRightClick($event, tab, index)" aria-haspopup="true" class="flex flex-row">
            <ContextMenu ref="tabMenu" :model="tabMenuItems" closeButtonProps="text" />
            <div class="flex ">
              <div v-if="editTab != index">
                {{ tab.name || 'Untitled' }}
                <Button class="p-button-rounded p-button-text p-button-sm" plain icon="pi pi-pencil"
                  @click="() => { editTab = index; nameBefore = tab.name; }" style="min-width: 0 !important;" />
                <Button class="p-button-rounded p-button-text p-button-sm" @click="onTabDelete($event, tab, index)" plain
                  icon="pi pi-times" style="min-width: 0 !important;" />
              </div>
              <div v-else class="flex">
                <InputText v-on:keyup.enter="onTabEdited(tab, index)" v-model="tab.name" />
                <Button class="p-button-rounded p-button-text p-button-sm" plain icon="pi pi-check"
                  @click="onTabEdited(tab, index)" style="min-width: 0 !important;" />
                <Button class="p-button-rounded p-button-text p-button-sm"
                  @click="() => { tab.name = nameBefore; editTab = -1; }" plain icon="pi pi-times" style="min-width: 0 !important;" />
              </div>
            </div>
          </div>
        </template>
        <WidgetsDashboardGrid :tabId="tab.id" />
      </TabPanel>

      <TabPanel>
        <template #header>
          <Button icon="pi pi-plus" class="p-button-rounded p-button-text p-button-sm" @click="onNewTab" />
        </template>
      </TabPanel>

    </TabView>
  </div>
</template>
<style scoped>
.p-button.p-button-icon-only {
  width: 0 !important;
}

</style>