/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateTabRequest } from '../models/CreateTabRequest';
import type { CreateTabResponse } from '../models/CreateTabResponse';
import type { DeleteTabRequest } from '../models/DeleteTabRequest';
import type { DeleteTabResponse } from '../models/DeleteTabResponse';
import type { ListTabsRequest } from '../models/ListTabsRequest';
import type { ListTabsResponse } from '../models/ListTabsResponse';
import type { UpdateTabRequest } from '../models/UpdateTabRequest';
import type { UpdateTabResponse } from '../models/UpdateTabResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TabsService {

    /**
     * @param requestBody 
     * @returns CreateTabResponse Success
     * @throws ApiError
     */
    public static postApiV1TabsCreate(
requestBody?: CreateTabRequest,
): CancelablePromise<CreateTabResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Tabs/Create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns UpdateTabResponse Success
     * @throws ApiError
     */
    public static putApiV1TabsUpdate(
requestBody?: UpdateTabRequest,
): CancelablePromise<UpdateTabResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/Tabs/Update',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param request 
     * @returns ListTabsResponse Success
     * @throws ApiError
     */
    public static getApiV1TabsList(
request?: ListTabsRequest,
): CancelablePromise<ListTabsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Tabs/List',
            query: {
                'request': request,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns DeleteTabResponse Success
     * @throws ApiError
     */
    public static deleteApiV1TabsDelete(
requestBody?: DeleteTabRequest,
): CancelablePromise<DeleteTabResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/Tabs/Delete',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
