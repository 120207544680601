/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum WidgetType {
    LINK = 'link',
    EMBEDDED = 'embedded',
    JIRA_TIME_BOOKING = 'jiraTimeBooking',
}
