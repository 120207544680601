/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateWidgetsResponse } from '../models/CreateWidgetsResponse';
import type { DeleteWidgetResponse } from '../models/DeleteWidgetResponse';
import type { EditWidgetLinkRequest } from '../models/EditWidgetLinkRequest';
import type { EditWidgetResponse } from '../models/EditWidgetResponse';
import type { EmbeddedCreateWidgetsRequest } from '../models/EmbeddedCreateWidgetsRequest';
import type { EmbeddedEditWidgetRequest } from '../models/EmbeddedEditWidgetRequest';
import type { JiraTimeBookingCreateWidgetsRequest } from '../models/JiraTimeBookingCreateWidgetsRequest';
import type { JiraTimeBookingEditWidgetRequest } from '../models/JiraTimeBookingEditWidgetRequest';
import type { JsonElementCreateWidgetsRequest } from '../models/JsonElementCreateWidgetsRequest';
import type { JsonElementEditWidgetRequest } from '../models/JsonElementEditWidgetRequest';
import type { LinkCreateWidgetsRequest } from '../models/LinkCreateWidgetsRequest';
import type { ListWidgetsOrderFields } from '../models/ListWidgetsOrderFields';
import type { ListWidgetsOrderFieldsOrderingFieldsBase } from '../models/ListWidgetsOrderFieldsOrderingFieldsBase';
import type { ListWidgetsResponse } from '../models/ListWidgetsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WidgetsService {

    /**
     * @param requestBody 
     * @returns CreateWidgetsResponse Success
     * @throws ApiError
     */
    public static postApiV1WidgetsCreate(
requestBody?: JsonElementCreateWidgetsRequest,
): CancelablePromise<CreateWidgetsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Widgets/Create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns CreateWidgetsResponse Success
     * @throws ApiError
     */
    public static postApiV1WidgetsCreateLink(
requestBody?: LinkCreateWidgetsRequest,
): CancelablePromise<CreateWidgetsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Widgets/CreateLink',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns CreateWidgetsResponse Success
     * @throws ApiError
     */
    public static postApiV1WidgetsCreateEmbedded(
requestBody?: EmbeddedCreateWidgetsRequest,
): CancelablePromise<CreateWidgetsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Widgets/CreateEmbedded',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns CreateWidgetsResponse Success
     * @throws ApiError
     */
    public static postApiV1WidgetsCreateJira(
requestBody?: JiraTimeBookingCreateWidgetsRequest,
): CancelablePromise<CreateWidgetsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Widgets/CreateJira',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param onlyShared 
     * @param pageNumber 
     * @param pageSize 
     * @param skip 
     * @param orderingFields 
     * @param availableOrderingFields 
     * @returns ListWidgetsResponse Success
     * @throws ApiError
     */
    public static getApiV1WidgetsList(
onlyShared?: boolean,
pageNumber?: number,
pageSize?: number,
skip?: number,
orderingFields?: Array<ListWidgetsOrderFieldsOrderingFieldsBase>,
availableOrderingFields?: ListWidgetsOrderFields,
): CancelablePromise<ListWidgetsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Widgets/List',
            query: {
                'OnlyShared': onlyShared,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
                'Skip': skip,
                'OrderingFields': orderingFields,
                'AvailableOrderingFields': availableOrderingFields,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns EditWidgetResponse Success
     * @throws ApiError
     */
    public static putApiV1WidgetsEdit(
requestBody?: JsonElementEditWidgetRequest,
): CancelablePromise<EditWidgetResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/Widgets/Edit',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns EditWidgetResponse Success
     * @throws ApiError
     */
    public static putApiV1WidgetsEditPrivate(
requestBody?: JsonElementEditWidgetRequest,
): CancelablePromise<EditWidgetResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/Widgets/EditPrivate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns EditWidgetResponse Success
     * @throws ApiError
     */
    public static putApiV1WidgetsEditLink(
requestBody?: EditWidgetLinkRequest,
): CancelablePromise<EditWidgetResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/Widgets/EditLink',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns EditWidgetResponse Success
     * @throws ApiError
     */
    public static putApiV1WidgetsEditEmbedded(
requestBody?: EmbeddedEditWidgetRequest,
): CancelablePromise<EditWidgetResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/Widgets/EditEmbedded',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns EditWidgetResponse Success
     * @throws ApiError
     */
    public static putApiV1WidgetsEditJira(
requestBody?: JiraTimeBookingEditWidgetRequest,
): CancelablePromise<EditWidgetResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/Widgets/EditJira',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param widgetId 
     * @returns DeleteWidgetResponse Success
     * @throws ApiError
     */
    public static deleteApiV1WidgetsDelete(
widgetId?: number,
): CancelablePromise<DeleteWidgetResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/Widgets/Delete',
            query: {
                'WidgetId': widgetId,
            },
        });
    }

}
