import { showError } from "@congatec/primevue-components-lib";
import { type ToastServiceMethods } from "primevue/toastservice";

export const req = async <T>(f: () => Promise<T | null | undefined>, toast: ToastServiceMethods | null = null): Promise<T | null | undefined> => {
  try {
    return await f();
  } catch (err: any) {
    console.error(err);
    if (toast) {
      showError(toast, "An error occured", err);
    }
  }

  return null;
}

