import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
//routers
import globalRouter from "./globalRouter";

import PrimeVue from "primevue/config";
import VueQrcodeReader from "vue-qrcode-reader";
import ToastService from "primevue/toastservice";
import "primeflex/primeflex.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import VueKonva from "vue-konva";

// import "../src/themes/md-light-indigo/theme.css";
import { createKeycloak } from "@congatec/authentication-lib";
import { createConComponents } from "@congatec/primevue-components-lib";
import axios from "axios";
import { OpenAPI as NeptuneOpenApi } from "@/apis/neptune-api";
import { OpenAPI as ErisOpenApi } from "@/apis/eris-api";
import { OpenAPI as UranusOpenApi } from "@/apis/uranus-api";
import { OpenAPI as SaturnOpenApi } from "@/apis/saturn-api";
import { OpenAPI as EarthOpenApi } from "@/apis/earth-api";
import { OpenAPI as AzureAiOpenApi } from "@/apis/azureai-api";
import { OpenAPI as CeresOpenApi } from "@/apis/ceres-api";
import { OpenAPI as MercuryOpenApi } from "@/apis/mercury-api";
import { OpenAPI as HaumeaOpenApi } from "@/apis/haumea-api";
import { OpenAPI as HubspotgatewayOpenApi } from "@/apis/hubspotgateway-api";
import { OpenAPI as JupiterOpenApi } from "@/apis/jupiter-api";
import { OpenAPI as AReadyOpenApi } from "@/apis/aready-api";
import { OpenAPI as LapCounterOpenApi } from "@/apis/lapcounter-api";
import ConfirmationService from "primevue/confirmationservice";
import Tooltip from "primevue/tooltip";
import DialogService from "primevue/dialogservice";
import {
  createEventBroker,
  setActiveEventBroker,
} from "@congatec/eventbroker-lib";

NeptuneOpenApi.BASE = import.meta.env.VITE_NEPTUNE_API_BASE_URL;
ErisOpenApi.BASE = import.meta.env.VITE_ERIS_API_BASE_URL;
UranusOpenApi.BASE = import.meta.env.VITE_URANUS_API_BASE_URL;
SaturnOpenApi.BASE = import.meta.env.VITE_SATURN_API_BASE_URL;
EarthOpenApi.BASE = import.meta.env.VITE_EARTH_API_BASE_URL;
AzureAiOpenApi.BASE = import.meta.env.VITE_AZUREAI_API_BASE_URL;
CeresOpenApi.BASE = import.meta.env.VITE_CERES_API_BASE_URL;
MercuryOpenApi.BASE = import.meta.env.VITE_MERCURY_API_BASE_URL;
HaumeaOpenApi.BASE = import.meta.env.VITE_HAUMEA_API_BASE_URL;
HaumeaOpenApi.BASE = import.meta.env.VITE_HAUMEA_API_BASE_URL;
HubspotgatewayOpenApi.BASE = import.meta.env.VITE_HUBSPOTGATEWAY_API_BASE_URL;
JupiterOpenApi.BASE = import.meta.env.VITE_JUPITER_API_BASE_URL;
AReadyOpenApi.BASE = import.meta.env.VITE_AREADY_API_BASE_URL;
LapCounterOpenApi.BASE = import.meta.env.VITE_LAPCOUNTER_API_BASE_URL;

const eventBroker = createEventBroker();

axios.interceptors.request.use((config) => {
  config.onUploadProgress = (progressEvent) => {
    // const percentCompleted = Math.round(
    //   (progressEvent.loaded * 100) / progressEvent.total!,

    // );
    console.log("progressEvent: ", progressEvent);
    eventBroker.publish("/upload-progress", { progress: progressEvent.progress });
  }
  return config;
})

setActiveEventBroker(eventBroker);
const pinia = createPinia();

const app = createApp(App);
if (!localStorage.getItem("congatecCredentials")) {
  const keycloak = createKeycloak({
    config: {
      url: import.meta.env.VITE_KEYCLOAK_URL,
      realm: import.meta.env.VITE_KEYCLOAK_REALM,
      clientId: import.meta.env.VITE_KEYCLOAK_CLIENTID,
    },
    init: {
      onLoad: "login-required",
      silentCheckSsoRedirectUri: `${window.location.origin}/slient-sso`,
    },
    pinia,
    axios,
    eventBroker,
  });
  app.use(keycloak);
} else {
  axios?.interceptors.request.use(async (req: any) => {
    if (req == undefined || req.headers == undefined) {
      return undefined;
    }

    const loginUrl =
      `${import.meta.env.VITE_KEYCLOAK_URL}/realms/${import.meta.env.VITE_KEYCLOAK_REALM}/protocol/openid-connect/token`
    const clientId = import.meta.env.VITE_KEYCLOAK_CLIENTID;
    
    const credentialsJson = localStorage.getItem("congatecCredentials")!;
    const credentials = JSON.parse(credentialsJson);

    const loginBody = {
      client_id: clientId,
      username: credentials.username,
      password: credentials.password,
      grant_type: 'password'
    };

    const response: any = await fetch(loginUrl, {
      method: 'POST',
      headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body: new URLSearchParams(loginBody)
    });
    const token = await response.json();
    console.log('Res', token.access_token)
    req.headers["Authorization"] = `Bearer ${token.access_token}`;
    return req;
  });
}


app.use(pinia);
app.use(globalRouter);
app.use(PrimeVue);
app.use(ToastService);
app.use(ConfirmationService);
app.use(VueQrcodeReader);
app.use(DialogService);
app.use(
  createConComponents({
    pinia,
    router: globalRouter,
    eventBroker: eventBroker,
  }),
);
app.use(VueKonva);
app.directive("tooltip", Tooltip);
app.mount("#app");
