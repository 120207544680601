<script setup lang="ts">
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { ref, watch } from "vue";
import { CategoriesService, type CreateCategoriesRequest } from "@/apis/earth-api";

const props = defineProps<{
  visible: boolean,
  onHide: (created: boolean) => any
}>();

const dialogVisible = ref(false);
const description = ref();
const name = ref();

watch(() => props.visible, (nv) => {
  console.log("VISIBLE: ", nv);
  if (nv != undefined) {
    dialogVisible.value = nv;
  }
}, { deep: true, immediate: true });

watch(() => dialogVisible.value, (nv) => {
  if (nv != null && !nv) {
    props.onHide(!!description.value);
  }
})

async function dispatchCreateCategoryAsync(r: CreateCategoriesRequest) {
  console.log("REQUEST: ", r);
  await CategoriesService.postApiV1CategoriesCreateCategory(r);
}

</script>
<template>
  <div>
    <Dialog v-if="dialogVisible" v-model:visible="dialogVisible" modal :draggable="false" header="Create Category"
      position="top" style="{
        max-width: 50%;
        min-width: 30%;
        max-height: 90%}">
      <template #default>
        <div class="card flex justify-content-left">
          <div class="flex flex-column gap-5">
            <div class="flex flex-column gap-1">
              <label for="name">Name</label>
              <InputText id="name" v-model="name" aria-describedby="name-help" />
              <small id="name-help">Enter the Name for the Category</small>
            </div>
            <div class="flex flex-column gap-1">
              <label for="description">Description</label>
              <InputText id="description" v-model="description" aria-describedby="description-help" />
              <small id="description-help">Enter the Description for the Category</small>
            </div>
          </div>
        </div>

      </template>
      <template #footer>
        <div class="flex flex-1 justify-content-between">
          <Button class="p-button-text" label="Cancel" :onClick="() => {
            dialogVisible = false;
          }" />
          <Button class="p-button-primary" label="Create" :onClick="async () => {
            //await dispatchCreateWidgetAsync(widgetCreateRequest);
            await dispatchCreateCategoryAsync({
              name: name,
              description: description,
            } as Partial<CreateCategoriesRequest>);
            dialogVisible = false;
          }" />
        </div>
      </template>
    </Dialog>
  </div>
</template>
