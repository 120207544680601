
<template>
  <div class="flex flex-column">
    <Dropdown id="widget-type-selector" v-model="selectedType" :options="types" placeholder="Select Widget Type">
      <template #value="{ value, placeholder }">
        <div v-if="value" class="flex gap-2">
          <WidgetTypeIcon :type="value" />
          <span>{{ value }}</span>
        </div>
        <span v-else>{{ placeholder }}</span>
      </template>
      <template #option="{ option }">
        <span>
          <WidgetTypeIcon :type="option" />
          {{ option }}
        </span>
      </template>
    </Dropdown>
  </div>
</template>

<script setup lang="ts">
import Dropdown from "primevue/dropdown";
import { WidgetType } from "@/apis/earth-api";
import { ref, watch } from "vue";
import {  WidgetTypeIcon } from "@/earth/components";

const emits = defineEmits(["onTypeChanged"])

const types = ref<WidgetType[]>(Object.values(WidgetType));
const selectedType = ref<WidgetType>();

watch(selectedType,nv =>{
  emits('onTypeChanged',nv);
})
</script>


<style>
  .p-dropdown-panel{
    z-index: 80000 !important;
  }
</style>