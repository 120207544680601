/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CloneTemplateRequest } from '../models/CloneTemplateRequest';
import type { CloneTemplateResponse } from '../models/CloneTemplateResponse';
import type { CreateTemplateRequest } from '../models/CreateTemplateRequest';
import type { CreateTemplateResponse } from '../models/CreateTemplateResponse';
import type { DeleteTemplateProfileRequest } from '../models/DeleteTemplateProfileRequest';
import type { DeleteTemplateProfileResponse } from '../models/DeleteTemplateProfileResponse';
import type { EditTemplateProfileRequest } from '../models/EditTemplateProfileRequest';
import type { EditTemplateProfileResponse } from '../models/EditTemplateProfileResponse';
import type { IsSudoRequest } from '../models/IsSudoRequest';
import type { IsSudoResponse } from '../models/IsSudoResponse';
import type { ListUsersWidgetConfigurationsOrderFields } from '../models/ListUsersWidgetConfigurationsOrderFields';
import type { ListUsersWidgetConfigurationsOrderFieldsOrderingFieldsBase } from '../models/ListUsersWidgetConfigurationsOrderFieldsOrderingFieldsBase';
import type { ListUsersWidgetConfigurationsResponse } from '../models/ListUsersWidgetConfigurationsResponse';
import type { SudoExitRequest } from '../models/SudoExitRequest';
import type { SudoExitResponse } from '../models/SudoExitResponse';
import type { SudoRequest } from '../models/SudoRequest';
import type { SudoResponse } from '../models/SudoResponse';
import type { TemplateListRequest } from '../models/TemplateListRequest';
import type { TemplateListResponse } from '../models/TemplateListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * @param userId 
     * @param pageNumber 
     * @param pageSize 
     * @param skip 
     * @param orderingFields 
     * @param availableOrderingFields 
     * @returns ListUsersWidgetConfigurationsResponse Success
     * @throws ApiError
     */
    public static getApiV1UsersWidgetConfigurations(
userId: number,
pageNumber?: number,
pageSize?: number,
skip?: number,
orderingFields?: Array<ListUsersWidgetConfigurationsOrderFieldsOrderingFieldsBase>,
availableOrderingFields?: ListUsersWidgetConfigurationsOrderFields,
): CancelablePromise<ListUsersWidgetConfigurationsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Users/{UserId}/WidgetConfigurations',
            path: {
                'UserId': userId,
            },
            query: {
                'PageNumber': pageNumber,
                'PageSize': pageSize,
                'Skip': skip,
                'OrderingFields': orderingFields,
                'AvailableOrderingFields': availableOrderingFields,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns CloneTemplateResponse Success
     * @throws ApiError
     */
    public static putApiV1UsersCloneTemplate(
requestBody?: CloneTemplateRequest,
): CancelablePromise<CloneTemplateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/Users/CloneTemplate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns SudoResponse Success
     * @throws ApiError
     */
    public static putApiV1UsersSudo(
requestBody?: SudoRequest,
): CancelablePromise<SudoResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/Users/Sudo',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns SudoExitResponse Success
     * @throws ApiError
     */
    public static putApiV1UsersExitSudo(
requestBody?: SudoExitRequest,
): CancelablePromise<SudoExitResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/Users/ExitSudo',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns CreateTemplateResponse Success
     * @throws ApiError
     */
    public static postApiV1UsersCreateTemplate(
requestBody?: CreateTemplateRequest,
): CancelablePromise<CreateTemplateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Users/CreateTemplate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns DeleteTemplateProfileResponse Success
     * @throws ApiError
     */
    public static deleteApiV1UsersDeleteTemplate(
requestBody?: DeleteTemplateProfileRequest,
): CancelablePromise<DeleteTemplateProfileResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/Users/DeleteTemplate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns EditTemplateProfileResponse Success
     * @throws ApiError
     */
    public static putApiV1UsersEditTemplate(
requestBody?: EditTemplateProfileRequest,
): CancelablePromise<EditTemplateProfileResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/Users/EditTemplate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param request 
     * @returns IsSudoResponse Success
     * @throws ApiError
     */
    public static getApiV1UsersIsSudoed(
request?: IsSudoRequest,
): CancelablePromise<IsSudoResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Users/IsSudoed',
            query: {
                'request': request,
            },
        });
    }

    /**
     * @param request 
     * @returns TemplateListResponse Success
     * @throws ApiError
     */
    public static getApiV1UsersTemplateList(
request?: TemplateListRequest,
): CancelablePromise<TemplateListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Users/TemplateList',
            query: {
                'request': request,
            },
        });
    }

}
