<template>
  <div class="flex flex-column gap-5 m-5 mt-7">
    <div class="w-full">
      <label for="fileName">Path <span>*</span></label>
      <InputText
        id="fileName"
        v-model="state.Path"
        :value="state.Path"
        placeholder="Enter the directory to upload to"
        class="w-full"
        :class="{
          'input-invalid': v$.Path.$invalid && v$.Path.$error,
          'h-3rem': true,
        }"
      />
      <span v-if="v$.Path.$error">{{ v$.Path.$errors[0].$message }} </span>
    </div>
    <div class="w-full">
      <label for="identifier">Identifier <span>*</span></label>
      <InputText
        id="identifier"
        v-model="state.Identifier"
        :value="state.Identifier"
        placeholder="Enter the Identifier"
        class="w-full"
        :class="{
          'input-invalid': v$.Identifier.$invalid && v$.Identifier.$error,
          'h-3rem': true,
        }"
      />
      <span v-if="v$.Identifier.$error"
        >{{ v$.Identifier.$errors[0].$message }}
      </span>
    </div>
    <FileUpload
      class="a-uploadprogress"
      :disabled="false"
      name="aReady[]"
      ref="fileUpload"
      customUpload
      @uploader="onFileUpload"
      :multiple="true"
      @select="onFileSelect"
      :maxFileSize="100000000000"
      @progress="showProgress"
      :class="{
        'input-invalid':
          v$.FileUploadBase64.$invalid && v$.FileUploadBase64.$error,
        'h-3rem': true,
      }"
    >
      <template #header="{ chooseCallback, clearCallback, files }">
        <div
          class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2"
        >
          <div class="flex gap-2">
            <Button
              @click="chooseCallback()"
              icon="pi pi-folder-open"
              rounded
              outlined
            ></Button>
            <Button
              @click="onFileUpload($event)"
              icon="pi pi-cloud-upload"
              rounded
              outlined
              severity="success"
              :disabled="!files || files.length === 0"
            ></Button>
            <Button
              @click="clearCallback()"
              icon="pi pi-times"
              rounded
              outlined
              severity="danger"
              :disabled="!files || files.length === 0"
            ></Button>
          </div>
          <div class="flex align-content-center align-items-center gap-3">
            <div>
              <label>Upload</label>
              <ProgressBar
                :value="totalSizePercent"
                :class="['md:w-20rem h-1rem w-full md:ml-auto']"
              >
                {{ totalSizePercent }}%
                <!-- <span>{{ totalSize }}B / 1Mb</span> -->
              </ProgressBar>

              <label class="mt-2">Processing</label>
              <ProgressBar
                :value="totalProcessingSizePercent"
                :class="['md:w-20rem h-1rem w-full md:ml-auto']"
              >
                {{ totalProcessingSizePercent }}%
                <!-- <span>{{ totalSize }}B / 1Mb</span> -->
              </ProgressBar>
            </div>
            <ProgressSpinner
              v-if="uploadInProgress"
              style="width: 40px; height: 40px"
              strokeWidth="8"
              fill="transparent"
              animationDuration=".5s"
              aria-label="Custom ProgressSpinner"
              v-tooltip.left="'Server upload in progress'"
            />
          </div>
        </div>
      </template>
      <template #empty>
        <p>Drag and drop files here to upload. <span>*</span></p>
      </template>
    </FileUpload>
    <span v-if="v$.FileUploadBase64.$error"
      >{{ v$.FileUploadBase64.$errors[0].$message }}
    </span>
    <!-- <ProgressBar :value="progress"></ProgressBar> -->
  </div>
</template>
<script setup lang="ts">
import { type Ref, ref, watch, onMounted } from "vue";
import InputText from "primevue/inputtext";
import FileUpload from "primevue/fileupload";
import { useToast } from "primevue/usetoast";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { usePrimeVue } from "primevue/config";
import ProgressBar from "primevue/progressbar";
import Button from "primevue/button";
import { onBeforeRouteLeave } from "vue-router";
import { ArtifactService, StorageService } from "@/apis/aready-api";
import { useEventBroker } from "@congatec/eventbroker-lib";
import ProgressSpinner from "primevue/progressspinner";

const eventBroker = useEventBroker();
eventBroker?.subscribe("/upload-progress", async (percentCompleted) => {
  totalSizePercent.value = Math.floor(percentCompleted.progress * 100);
});

const $primevue = usePrimeVue();
const toast = useToast();
const files: Ref<any[]> = ref([]);
const totalSize = ref(0);
const totalSizePercent = ref(0);
const totalProcessingSizePercent = ref(0);
const progress = ref(0);
const uploadInProgress = ref(false);
const fileUpload = ref();

const state = ref({
  Path: "",
  Identifier: "",
  PlmItem: "",
  FileUploadBase64: [
    { FileName: new String(null), FileContent: new String(null) },
  ],
});

//vuelidate
const rules = {
  FileUploadBase64: { required },
  Path: { required },
  Identifier: { required },
};
const v$ = useVuelidate(rules, state);

const beforeUnloadListener = (event: any) => {
  if (uploadInProgress.value) {
    event.preventDefault();
  }
};

const sleepAsync = async (ms: number = 10) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const onFileUpload = async (event: any) => {
  uploadInProgress.value = true;
  state.value.FileUploadBase64 = [];
  window.addEventListener("beforeunload", beforeUnloadListener);

  try {
    console.log("fileUploadEvent: ", event);
    if (files.value) {
      //const formData = new FormData();
      await Promise.all(
        files.value.map(async (file, index) => {
          //formData.append('files', file);
          //await convertToBase64(file);

          let identifier = state.value.Identifier;

          if (index > 0) {
            identifier = identifier + "_" + index;
          }
          const res = await ArtifactService.postApiV1Artifacts(
            state.value.Path,
            identifier,
            "",
            { FileData: file },
          );
          totalSizePercent.value = 100;
          fileUpload.value.uploadedFiles.push(file);

          let progress = 0;
          while (progress < 100) {
            const status = await ArtifactService.getApiV1ArtifactsCreateStatus(
              res.uploadGuid || "",
            );
            progress = status.progress || 0;
            totalProcessingSizePercent.value = progress;
            await sleepAsync(5000);
          }
        }),
      );
      //console.log("uploadedData: ", formData);
      // set seleced files as uploadedFiles so that the status badge shows as completed
      fileUpload.value.files = [];
      uploadInProgress.value = false;
      toast.add({
        severity: "info",
        summary: "Success",
        detail: "File Uploaded",
        life: 3000,
      });
      window.removeEventListener("beforeunload", beforeUnloadListener);
    }
  } finally {
    uploadInProgress.value = false;
  }
  //callback();
};

const onFileSelect = (event: any) => {
  console.log("selectEvent: ", event);
  totalSize.value = 0;
  if (event.files) {
    files.value = event.files;
    files.value.forEach((file) => {
      totalSize.value += file.size ?? 0;
    });
  }
  totalSizePercent.value = 0;
  totalProcessingSizePercent.value = 0;

  //   imageUrl.value = event.files[0].objectURL;
  //   // set seleced files as uploadedFiles so that the status badge shows as completed
  //   imageUpload.value.uploadedFiles = imageUpload.value.files;
  //   imageUpload.value.files = [];
  //   toast.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 });
  //   convertToBase64(event.files[0]);
};

const showProgress = (event: any) => {
  totalSizePercent.value =
    (event.originalEvent.loaded * 100) / event.originalEvent.total;
  progress.value = event.originalEvent.value;
};

onBeforeRouteLeave((to, from) => {
  if (uploadInProgress.value === true) {
    const answer = window.confirm(
      "Do you really want to leave? You have unfinished uploads!",
    );
    // cancel the navigation and stay on the same page
    if (!answer) return false;
  }
});
</script>
<style>
.p-fileupload .p-progressbar {
  /* height: 1rem !important; */
  /* display: none; */
}

.p-progressbar.p-progressbar-determinate .p-progressbar-label {
  display: flex;
}

.p-fileupload-content .p-progressbar {
  display: none;
}
</style>
