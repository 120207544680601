<template>
    <div class="flex flex-column m-2 gap-4">
        <WidgetTypeSelector @onTypeChanged="changeWidgetType"/>
        <div class="flex align-items-center gap-2" v-if="isAdmin">
            <label for="public-switch">Public</label>
            <InputSwitch id="public-switch" v-model="isPublic" />
            <FileUploader @selected="changeData" v-model="base64PreviewImage"></FileUploader>
            <WidgetCategorySelector @selected="changeWidgetCategories"/>
            <Dropdown v-model="selectedSize" :options="sizes" placeholder="Select Widget Size"/>
        </div>
        <ModelUpdater :type="type" @modelUpdated="updateModel" isWidget/>
    </div>
    <div class="flex flex-row m-2 mt-5 justify-content-between">
        <Button label="Cancel" @click="onCancel()" />
        <Button label="Confirm" @click="dispatchCreateWidgetAsync()" />
    </div>
</template>
<script setup lang="ts">
import { WidgetType,  type Link, type Embedded, WidgetsService, CategoriesService, StandardSize} from '@/apis/earth-api';
import { ref } from 'vue';
import type { KeycloakService } from "@congatec/authentication-lib";
import { onKeycloakToken } from '@congatec/authentication-lib';
import { ModelUpdater, WidgetTypeSelector, WidgetCategorySelector, FileUploader } from '@/earth/components';
import Button from 'primevue/button';
import InputSwitch from 'primevue/inputswitch';
import Dropdown from 'primevue/dropdown';


const type = ref<WidgetType>();
const model = ref<Link & Embedded>({});
const categories = ref<string[]>([]);
const selectedSize = ref<StandardSize>();
const sizes = ref<StandardSize[]>(Object.values(StandardSize));

//admin data
const base64PreviewImage = ref<string>("");
const isPublic = ref<boolean>(false);
const isAdmin = ref<boolean>(false);

onKeycloakToken(async (authenticationService: KeycloakService) => {
    isAdmin.value = authenticationService.hasRoles(["Admin", "SystemAdmin"],import.meta.env.VITE_KEYCLOAK_JUPITER_CLIENTID);
});

const emits = defineEmits(['onCancel','widgetCreated'])

function changeData(newData: any) {
    const reader = new FileReader();
    reader.readAsBinaryString(newData)
    reader.onload = function () {
      base64PreviewImage.value = btoa(reader.result as string)
    }
}

function onCancel(){
    emits("onCancel")
}

function changeWidgetType(widgetType:WidgetType){
    type.value = widgetType;
}

function changeWidgetCategories(selectedCategories : string[]){
    categories.value = selectedCategories
}

function updateModel(widgetModel: Link | Embedded){
    console.log('Model updated', widgetModel)
    model.value.title = widgetModel.title;
    model.value.subtitle = widgetModel.subtitle;
    model.value.details = widgetModel.details;
    switch(type.value){
        case WidgetType.LINK:{
            if('linkUrl' in widgetModel){
                model.value.linkUrl = widgetModel.linkUrl;
                
            }
            if('linkText' in widgetModel){
                model.value.linkText = widgetModel.linkText;
            }
            break;
        }
        case WidgetType.EMBEDDED:{
            if('url' in widgetModel){
                model.value.url = widgetModel.url;
            }
            break;
        }
    }
}

async function dispatchCreateWidgetAsync() {
    console.log("Widget model before create:", model.value)
    let widget = await WidgetsService.postApiV1WidgetsCreate({
        type : type.value,
        public : isPublic.value,
        base64PreviewImage : base64PreviewImage.value,
        model : model.value,
        columnSpan: selectedSize.value,
        rowSpan: selectedSize.value
    });
    
    await CategoriesService.postApiV1CategoriesCreateWidgetCategory({
        widgetId: widget.id,
        categoriesNameList: categories.value
    })
    emits('widgetCreated') 
//TODO: do this check in api
//    if(r.type == 'link'){
//             for(const key in r.model){
//                 let value : String = r.model[key]
//                 if(key == "linkUrl" && !value.startsWith("http://") && !value.startsWith("https://")){
//                     r.model[key] = "http://" + value
//                 }
//             }
//         }
}

</script>