<template>
    <div>
        <ConfirmDialog />
        <ConCard>
            <template #title>
                Template
            </template>
            <template #content>
                <div class="flex flex-row gap-3 mb-3">

                    <Dropdown class="w-4" v-model="selectedTemplate" @change="onChange($event)"
                        option-label="name" option-group-children="name" :options="templates ?? []"
                        placeholder="Select a template">

                    </Dropdown>
                    
                    <Button severity="danger" :disabled="selectedTemplate.profileId == null" label="Delete"
                        @click="confirmDelete()">
                    </Button>
                    <Button :disabled="selectedTemplate.profileId == null || isImpersonateMode" label="Impersonate"
                        @click="onImpersonate()">
                    </Button>
                    <Button label="Exit Impersonate" @click="onExitSudo()">
                    </Button>
                </div>
                <div class="flex flex-row gap-3">
                    <InputText class="w-4" type="text" v-model="newTemplate" placeholder="Input template name" />
                    <Button :disabled="newTemplate == null || newTemplate == ''" label="Create" @click="onTemplateCreate()">
                    </Button>
                    <Button :disabled="selectedTemplate.profileId == null || newTemplate == null || newTemplate == ''" label="Edit"
                        @click="confirmEdit()">
                    </Button>
                </div>
            </template>
        </ConCard>
        <!-- <Divider></Divider>
        <ConCard>
            <template #title>
                Create Template
            </template>
            <template #content>
                <div class="flex flex-row gap-3">
                    <InputText type="text" v-model="newTemplate" placeholder="Input template name" />
                    <Button label="Create" @click="onTemplateCreate()">
                    </Button>
                </div>
            </template>
        </ConCard> -->
    </div>
</template>
<script setup lang="ts">
import { type SudoRequest, UsersService, type CreateTemplateRequest } from '@/apis/earth-api';
import { type TemplateListResponse_Template } from '@/apis/earth-api';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import { onMounted, ref, type Ref, inject } from 'vue';
import { useToast } from 'primevue/usetoast';
import { ConCard } from '@congatec/primevue-components-lib';
import InputText from 'primevue/inputtext';
import Divider from 'primevue/divider';
import ConfirmDialog from 'primevue/confirmdialog';
import { useConfirm } from 'primevue/useconfirm';

const toast = useToast();
const confirm = useConfirm();
const selectedTemplate: Ref<TemplateListResponse_Template> = ref({});
var templates = ref<TemplateListResponse_Template[]>([]);
// var templateNames: Ref<Array<string | null | undefined>> = ref([]);
const newTemplate: Ref<string | null> = ref(null);
const isImpersonateMode: Ref<boolean> | undefined = inject('isImpersonateMode');

onMounted(async () => {
    await getTemplates();
})

async function getTemplates() {
    var templateResponse = await UsersService.getApiV1UsersTemplateList(); //ref<TemplateListResponse_Template>({})
    if (templateResponse.list) {
        templates.value = templateResponse.list;
        // templates.value.forEach(template => {
        //     templateNames.value.push(template.name);
        // });
    }
}

const onImpersonate = async () => {
    var request: SudoRequest = { userId: selectedTemplate.value.profileId }
    try {
        await UsersService.putApiV1UsersSudo(request);
        toast.add({ severity: 'success', summary: 'Success', detail: 'Entered Impersonate-Mode', life: 3000 });
        // TODO reload page with confirm dialog?
        window.location.reload();
    }
    catch (ex: any) {
        toast.add({ severity: 'error', summary: 'Error', detail: 'An error occured: ' + ex.message, life: 3000 });
    }

}

const confirmEdit = async () => {
    confirm.require({
        message: 'Do you want to rename the Template "' + selectedTemplate.value.name + '"" to ""' + newTemplate.value + '"?',
        header: 'Edit Confirmation',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-info',
        accept: async () => {
            try {
                await UsersService.putApiV1UsersEditTemplate({ id: selectedTemplate.value.profileId, name: newTemplate.value })
                getTemplates();
                newTemplate.value = '';
                selectedTemplate.value.profileId = undefined;
                toast.add({ severity: 'success', summary: 'Edit', detail: 'Template edited', life: 3000 });
            }
            catch (ex: any) {
                toast.add({ severity: 'error', summary: 'Error', detail: 'An error occured while editing the Template: ' + ex.message, life: 3000 });
            }
        },
        reject: async () => {
            // toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        }
    });

}

const confirmDelete = () => {
    confirm.require({
        message: 'Do you want to delete the Template "' + selectedTemplate.value.name + '"?',
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-info',
        accept: async () => {
            try {
                await UsersService.deleteApiV1UsersDeleteTemplate({ id: selectedTemplate.value.profileId });
                getTemplates();
                toast.add({ severity: 'success', summary: 'Delete', detail: 'Template deleted', life: 3000 });
            }
            catch (ex: any) {
                toast.add({ severity: 'error', summary: 'Error', detail: 'An error occured while deleting the Template: ' + ex.message, life: 3000 });
            }
        },
        reject: async () => {
            // toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        }
    });
}

const onExitSudo = async () => {
    try {
        await UsersService.putApiV1UsersExitSudo({});
        toast.add({ severity: 'success', summary: 'Success', detail: 'Exitet Impersonate-Mode', life: 3000 });
        // TODO reload page with confirm dialog?
        window.location.reload();
    }
    catch (ex: any) {
        toast.add({ severity: 'error', summary: 'Error', detail: 'An error occured: ' + ex.message, life: 3000 });
    }
}

const onTemplateCreate = async () => {
    try {
        if (newTemplate.value) {
            var request: CreateTemplateRequest = { name: newTemplate.value };
            await UsersService.postApiV1UsersCreateTemplate(request);
            toast.add({ severity: 'success', summary: 'Success', detail: 'Template created.', life: 3000 });

            await getTemplates();
        }
    }
    catch (ex: any) {
        toast.add({ severity: 'error', summary: 'Error', detail: 'An error occured: ' + ex.message, life: 3000 });
    }
}

const onChange = (event: any) => {
    console.log("onChange: ", event);
    console.log("selectedTemplate: ", selectedTemplate);

}
</script>